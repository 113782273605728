
import { CommitData } from "@/store/modules/review";
import { Component, Vue, Prop } from "vue-property-decorator";

import * as typeUtils from "../../../../shared/typeUtils";

@Component
export default class CommitSelectItem extends Vue {
  @Prop() v!: CommitData;
  @Prop() active!: boolean;
  @Prop() selected!: boolean;
  @Prop() disabled!: boolean;
  @Prop() included!: boolean;

  public commitTimestamp(commit: CommitData) {
    const date = commit.commit.pushedDate || commit.commit.committedDate;
    if (!date) {
      return;
    }

    const d = Date.parse(date);
    return typeUtils.formatTimestampLong(d);
  }

  public handleClick(e: MouseEvent) {
    if (this.disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  }

  public ellipsize(val: string, maxLength: number) {
    if (val.length < maxLength) {
      return val;
    }

    return val.substring(0, maxLength) + "...";
  }
}
