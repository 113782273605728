
import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import * as events from "../../plugins/events";

import UIModule, { Message } from "../../store/modules/ui";

@Component
export default class MessageStack extends Vue {
  private uiModule = getModule(UIModule, this.$store);

  public dismissMessage(msg: Message) {
    this.uiModule.dismissMessage(msg);
  }

  public doMessageAction(msg: Message) {
    if (msg.action) {
      events.emit(msg.action, {});
    }

    this.uiModule.dismissMessage(msg);
  }

  public classObj(msg: Message) {
    const hasAction = !!msg.action;
    const isError = msg.type === "error" || msg.type === "blocking_error";
    const isAlert = msg.type === "alert";

    return {
      "text-brand-400": isAlert,
      "text-red-500": isError,
      "cursor-pointer hover:bg-app-3 hover:border hover:border-brand-500": hasAction
    };
  }

  public msgIcon(msg: Message) {
    switch (msg.type) {
      case "alert":
        return "bell";
      case "error":
      case "blocking_error":
        return "circle-exclamation";
    }
  }

  get messages() {
    return this.uiModule.messages;
  }

  get hasBlockingError() {
    return this.uiModule.messages.some(e => e.type === "blocking_error");
  }
}
