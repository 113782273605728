
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class LabeledSelect extends Vue {
  @Prop({ default: null }) value!: string | null;

  @Prop() label!: string;
  @Prop() keys!: string[];
  @Prop() values!: any[];
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "right" }) align!: string;

  public showDropdown = false;
  public activeIndex = 0;

  mounted() {}

  onSelected(key: string) {
    this.showDropdown = false;
    this.$emit("input", key);
  }

  onShowDropdown() {
    if (!this.disabled) {
      if (!this.showDropdown) {
        this.showDropdown = true;
      } else {
        this.onHideDropdown();
      }
    }
  }

  onHideDropdown() {
    this.showDropdown = false;
    this.activeIndex = 0;
  }

  get selectedValue() {
    if (this.value === null || this.keys === null) {
      return null;
    }

    const selectedKeyIndex = this.keys.findIndex(k => k === this.value);
    if (selectedKeyIndex < 0) {
      return null;
    }

    return this.values[selectedKeyIndex];
  }
}
