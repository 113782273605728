
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ClickToCopy extends Vue {
  @Prop() text!: string;
  @Prop({ default: "clipboard" }) icon!: string;
  @Prop({ default: "Copy" }) tooltip!: string;

  public done: boolean = false;

  public copy() {
    this.done = true;
    setTimeout(() => {
      this.done = false;
    }, 1000);

    navigator.clipboard
      .writeText(this.text)
      .then(() => console.log(`Copied "${this.text}"`))
      .catch(e => console.warn("Unable to copy", e));
  }
}
