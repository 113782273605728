
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
  AddCommentAction,
  Comment,
  ReviewHistory
} from "../../../../shared/types";
import ReviewModule from "../../store/modules/review";

@Component({
  components: {}
})
export default class PullRequestDebug extends Vue {
  private reviewModule = getModule(ReviewModule, this.$store);

  get histories(): ReviewHistory[] {
    return this.reviewModule.histories;
  }

  get resetLink() {
    const metadata = this.reviewModule.review.metadata;
    const owner = metadata.owner;
    const repo = metadata.repo;
    const number = metadata.number;

    return `http://localhost:5001/codeapprove-dev/us-central1/resetReview?owner=${owner}&repo=${repo}&number=${number}`;
  }

  public getCommentsForHistory(history: ReviewHistory): Comment[] {
    const evts = history.actions.filter(
      a => a.type === "add_comment"
    ) as AddCommentAction[];
    const commentIds = evts.map(e => e.commentId);
    return this.reviewModule.comments.filter(c => commentIds.includes(c.id));
  }

  public getDataForComment(comment: Comment) {
    const thread = this.reviewModule.threadById(comment.threadId);
    if (!thread) {
      return {};
    }

    const originalArgs =
      thread.currentArgs.sha !== thread.originalArgs.sha
        ? thread.originalArgs
        : { hidden: true };

    const lastValidArgs = thread.lastValidArgs ?? {};

    return {
      commentId: comment.id,
      threadId: thread.id,
      text: comment.text,
      currentArgs: thread.currentArgs,
      originalArgs,
      lastValidArgs
    };
  }
}
