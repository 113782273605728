
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import SearchModal from "@/components/elements/SearchModal.vue";

import { Github, RepoSearchItem } from "../../../../shared/github";
import { config } from "../../plugins/config";
import AuthModule from "../../store/modules/auth";

@Component({
  components: {
    SearchModal
  }
})
export default class RepoSearchModal extends Vue {
  public query: string = "";
  public items: RepoSearchItem[] = [];

  private github!: Github;
  private authModule = getModule(AuthModule, this.$store);
  private searchFn: Function = () => {};

  async mounted() {
    this.github = new Github(
      AuthModule.getDelegate(this.authModule),
      config.github
    );

    this.searchFn = this.debounce(async () => {
      if (!this.query || this.query.length === 0) {
        return;
      }

      const userReposP = this.github.listUserRepos();
      const searchReposP = this.github.searchRepos(this.query);
      await Promise.all([userReposP, searchReposP]);

      const userRes = await userReposP;
      const userRepos = userRes
        .filter(r =>
          r.full_name.toLowerCase().includes(this.query.toLowerCase())
        )
        .slice(0, 10);

      const searchRes = await searchReposP;
      const searchRepos = searchRes.filter(
        r => !userRepos.some(x => x.full_name === r.full_name)
      );

      const repos = [
        ...userRepos,
        ...searchRepos.slice(0, 10 - userRepos.length)
      ];

      this.items = repos;
    }, 300);
  }

  @Watch("query")
  async onQuery() {
    if (this.query === "") {
      this.items = [];
    }

    this.searchFn();
  }

  public onSelected(e: { item: RepoSearchItem }) {
    this.$emit("selected", e);
  }

  private debounce(func: Function, waitFor: number): Function {
    let timeout: any = undefined;

    const debounced = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(), waitFor);
    };

    return debounced;
  }
}
