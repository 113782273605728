
import { getBooleanFlag, FLAGS } from "@/plugins/flags";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Home extends Vue {
  public enableRequireInvite = getBooleanFlag(FLAGS.ENABLE_REQUIRE_INVITE);

  public features = [
    {
      title: "Better commenting",
      body:
        'All comments are tracked and easily surfaced, even if they become "outdated" by a new commit. Add a comment on any line in the file, not just the lines in the diff.',
      icon: "/icon-commenting.png"
    },
    {
      title: "Track resolution",
      body:
        "Every comment thread must be resolved before the review is completed. Easily see what comments need your attention.",
      icon: "/icon-track.png"
    },
    {
      title: "A single screen",
      body:
        "See the diff, comments, review history, and CI status on a single page. No more changing tabs. Quickly filter diffs and comments together to see only what changed since your last review.",
      icon: "/icon-fullpicture.png"
    },
    {
      title: "Keyboard shortcuts",
      body:
        "Navigate files, lines, and comments without ever touching your mouse.",
      icon: "/icon-shortcuts.png"
    },
    {
      title: "Manage reviewers",
      body:
        "Assignees are automatically changed after each round of comments, so everyone knows when it's their turn. Add anyone on GitHub as a reviewer, not just repository collaborators.",
      icon: "/icon-reviewers.png"
    },
    {
      title: "Minimal permissions",
      body:
        "CodeApprove only needs permission to read your code, no need to grant full repository access.",
      icon: "/icon-permissions.png"
    }
  ];

  get title() {
    return "CodeApprove";
  }

  get signUpPath() {
    return this.enableRequireInvite ? "/interest" : "/signin";
  }

  async mounted() {}
}
