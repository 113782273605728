
import { Component, Prop, Vue, Watch, Mixins } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MarkdownContent from "@/components/elements/MarkdownContent.vue";
import ProgressBar from "@/components/elements/ProgressBar.vue";
import ToggleSwitch from "@/components/elements/ToggleSwitch.vue";
import Avatar from "@/components/elements/Avatar.vue";
import RichTextArea from "@/components/elements/RichTextArea.vue";
import ThreeDotMenu from "@/components/elements/ThreeDotMenu.vue";
import ClickToCopy from "@/components/elements/ClickToCopy.vue";

import {
  Thread,
  Comment,
  Side,
  THREAD_LINE_OUTDATED,
  THREAD_LINE_WHOLE_FILE
} from "../../../../shared/types";
import AuthModule from "../../store/modules/auth";
import ReviewModule from "../../store/modules/review";
import UIModule from "../../store/modules/ui";
import * as events from "../../plugins/events";
import * as dom from "../../plugins/dom";
import { CommentThreadAPI } from "../api";
import { KeyMap, COMMENT_THREAD_KEY_MAP } from "../../plugins/hotkeys";

import * as typeUtils from "../../../../shared/typeUtils";

type Mode = "inline" | "standalone";

interface CommentDisplay {
  comment: Comment;
  sending: boolean;
}

interface EditState {
  comment: Comment;
  draft: string;
  resolved: boolean;
}

@Component({
  components: {
    MarkdownContent,
    ProgressBar,
    ToggleSwitch,
    Avatar,
    RichTextArea,
    ThreeDotMenu,
    ClickToCopy
  }
})
export default class CommentThread extends Vue implements CommentThreadAPI {
  @Prop({ default: "inline" }) mode!: Mode;
  @Prop({ default: null }) side!: Side | null;
  @Prop() line!: number;
  @Prop({ default: null }) threadId!: string | null;
  @Prop({ default: null }) proposedThreadId!: string | null;
  @Prop({ default: false }) newDiscussion!: boolean;

  authModule = getModule(AuthModule, this.$store);
  reviewModule = getModule(ReviewModule, this.$store);
  uiModule = getModule(UIModule, this.$store);

  thread: Thread | null = null;

  remoteComments: Comment[] = [];
  localComments: Comment[] = [];

  resolvedToggle = !this.newDiscussion;

  showBody = true;
  forceShow = false;
  forceExpand = false;
  textFocus = false;

  draftComment: string = "";
  editState: EditState | null = null;

  mounted() {
    events.on(events.NEW_COMMENT_EVENT, this.onNewComment);
    this.loadComments();

    if ((this.mode === "inline" || this.newDiscussion) && !this.hasComments) {
      this.focusForm();
    }

    if (this.mode === "inline") {
      this.showBody = !(this.thread && this.thread.resolved);
    }

    if (this.mode === "standalone") {
      this.showBody = !this.isFullyResolved;
    }
  }

  destroyed() {
    events.off(events.NEW_COMMENT_EVENT, this.onNewComment);
  }

  get sha() {
    if (this.side) {
      return this.side === "left"
        ? this.reviewModule.viewState.base
        : this.reviewModule.viewState.head;
    }

    return null;
  }

  get numHiddenComments() {
    let count = 0;
    for (let i = 0; i < this.comments.length; i++) {
      if (!this.shouldShowComment(i)) {
        count++;
      }
    }

    return count;
  }

  get shouldShowThread() {
    if (this.forceShow) {
      return true;
    }

    // Never hide a thread with drafts
    const hasDrafts = this.comments.some(c => c.draft);
    if (hasDrafts) {
      return true;
    }

    // Hide _fully_ resolve threads in inline view
    return !(this.isInline && this.isFullyResolved);
  }

  public shouldShowComment(index: number) {
    if (this.forceExpand) {
      return true;
    }

    if (index === 0) {
      return true;
    }

    if (this.comments[index].draft) {
      return true;
    }

    // Replies are non-first comments, and we want to show later
    // replies first. So if maxRepliesToShow == 2
    // 0 - show
    // 1 - hide
    // 2 - hide
    // 3 - hide
    // 4 - show
    // 5 - show

    // 1 when collapsed, 2 if expanded and resolved, 3 if expanded and unresolved
    const maxRepliesToShow = !this.showBody ? 1 : this.willBeResolved ? 2 : 3;
    const numReplies = this.comments.length - 1;
    const numRepliesToHide = numReplies - maxRepliesToShow;

    return index - numRepliesToHide > 0;
  }

  public forceShowAndExpand() {
    this.forceShow = true;
    this.toggleCollapse(true);
  }

  public toggleCollapse(forceExpand: boolean) {
    this.showBody = !this.showBody;
    this.forceExpand = forceExpand;
  }

  get threadHash() {
    if (!this.thread) {
      return "";
    }
    return `thread-${this.thread.id}`;
  }

  get threadLink() {
    if (!this.thread) {
      return "";
    }

    const u = new URL(window.location.href);
    u.hash = this.threadHash;
    return u.href;
  }

  get willBeResolved() {
    if (this.newDiscussion || !this.thread) {
      return this.resolvedToggle;
    }

    if (this.lastResolvingComment != null) {
      return !!this.lastResolvingComment.setThreadResolution;
    }

    if (this.thread) {
      return this.thread.resolved;
    }

    return false;
  }

  get isFullyResolved() {
    if (!this.thread) {
      return false;
    }

    return typeUtils.isFullyResolved(this.thread, this.comments);
  }

  get isInline() {
    return this.mode === "inline";
  }

  get isStandalone() {
    return this.mode === "standalone";
  }

  get hotKeyMap(): KeyMap {
    return COMMENT_THREAD_KEY_MAP(this);
  }

  get reviewMeta() {
    return this.reviewModule.review.metadata;
  }

  private loadComments() {
    if (this.threadId) {
      this.thread = this.reviewModule.threadById(this.threadId);
      this.remoteComments = this.reviewModule.visibleCommentsByThread(
        this.threadId,
        this.authModule.assertUser.username
      );
    } else {
      this.thread = null;
      this.remoteComments = [];
    }

    this.localComments = [];

    // Set the resolution state. Default to resolved except new threads
    const defaultResolve = typeUtils.getOrDefault(
      this.reviewModule.repo.default_resolve_threads,
      true
    );

    this.resolvedToggle = defaultResolve && this.hasComments;

    // Emit an event when all settled
    if (this.remoteComments.length > 0 && this.localComments.length === 0) {
      this.$emit("settled");
    }
  }

  private onNewComment(event: events.NewCommentEvent) {
    if (
      event.threadId === this.threadId ||
      event.threadId === this.proposedThreadId
    ) {
      this.loadComments();
    }
  }

  get threadIsDiscussion() {
    return (
      this.newDiscussion || (this.thread && this.thread.originalArgs.sha === "")
    );
  }

  get threadIsWholeFile() {
    return (
      this.thread && this.thread.originalArgs.line === THREAD_LINE_WHOLE_FILE
    );
  }

  get threadHasLine() {
    return !this.threadIsDiscussion && !this.threadIsWholeFile;
  }

  get fileName() {
    if (this.thread) {
      return this.thread.currentArgs.file;
    }

    return "";
  }

  get username() {
    return this.authModule.assertUser.username;
  }

  get photoURL() {
    return this.authModule.assertUser.photoURL;
  }

  get typing() {
    return this.textFocus || this.hasDraft;
  }

  get showTextArea() {
    return !this.hasComments || this.typing || this.newDiscussion;
  }

  get sendDisabled() {
    // TODO: Also disable when uploading an image
    return !this.hasDraft;
  }

  get editing() {
    return this.editState !== null;
  }

  get hasDraft() {
    return this.draftComment.length > 0;
  }

  get outdated(): boolean {
    return (
      this.thread != null &&
      this.thread.currentArgs.line === THREAD_LINE_OUTDATED
    );
  }

  get lastResolvingComment(): Comment | null {
    const allResolving = this.comments.filter(
      c => c.setThreadResolution !== null
    );
    if (allResolving.length > 0) {
      return allResolving[allResolving.length - 1];
    }

    return null;
  }

  public resolutionBefore(commentId: string): boolean {
    const index = this.comments.findIndex(c => c.id === commentId);
    if (index < 0) {
      return this.threadWillResolve;
    }

    const commentsBefore = this.comments.slice(0, index);
    const changingComments = commentsBefore.filter(
      c => c.setThreadResolution !== null
    );

    if (changingComments.length > 0) {
      const lastChange =
        changingComments[changingComments.length - 1].setThreadResolution;
      return !!lastChange;
    } else if (this.thread) {
      return this.thread.resolved;
    } else {
      return false;
    }
  }

  get threadWillResolve() {
    // Check for the last comment which changes the status
    const comment = this.lastResolvingComment;
    if (comment != null) {
      return comment.setThreadResolution as boolean;
    }

    // Otherwise, use the thread state
    if (this.thread) {
      return this.thread.resolved;
    }

    return false;
  }

  get hasComments(): boolean {
    return this.comments.length > 0;
  }

  public async quickResolve() {
    this.draftComment = "Resolved";
    this.addComment(true);
  }

  public async saveEdit() {
    if (!this.editState) {
      return;
    }

    const { comment, resolved, draft } = this.editState;

    // Only need to set state if this changes the consensus reached by
    // all *previous* comments
    const resolutionBefore = this.resolutionBefore(comment.id);
    const setThreadResolution = resolved === resolutionBefore ? null : resolved;

    await this.reviewModule.editDraftComment({
      id: comment.id,
      text: draft,
      setThreadResolution
    });

    this.editState = null;
  }

  public async addComment(explicitResolve?: boolean) {
    console.log(`CommentThread#addComment(${explicitResolve})`);

    if (!this.hasDraft) {
      console.log("addComment: no draft, not adding.");
      return;
    }

    // If no value is passed in explicitly (for example, ctrl + enter shortcut)
    // then we just use the toggle value.
    const setResolved =
      explicitResolve === undefined ? this.resolvedToggle : explicitResolve;

    const replyToThread = this.thread !== null;
    const startNewThread = this.thread === null;

    // Our unique information is the content of the comment and the resolution intent
    // Start the event chain which goes through Chunk, ChangeEntry, and PullRequest

    // If we already know the threadId we can fill in all this information
    // at the last second.
    if (replyToThread) {
      // If the new setting is different than the thread's state
      // set it explicitly
      const resolve =
        setResolved === this.threadWillResolve ? undefined : setResolved;

      const partialEvt: events.ReplyAddCommentEvent = {
        type: "reply_add_comment",
        threadId: this.thread!.id,
        content: this.draftComment,
        resolve
      };

      this.emitComment(partialEvt);
    }

    // If we are a new comment, we need to fill in what we know
    if (startNewThread) {
      const resolve = setResolved ? true : undefined;
      const content = this.draftComment;
      let partialEvt: Partial<events.NewThreadAddCommentEvent> = {
        type: "new_thread_add_comment",
        side: this.side || undefined,
        content,
        resolve
      };

      if (this.newDiscussion) {
        partialEvt = events.getNewDiscussionEvent({ content, resolve });
      } else {
        if (this.sha) {
          partialEvt.sha = this.sha;
        }

        if (this.line) {
          partialEvt.line = this.line;
        }
      }

      if (this.proposedThreadId) {
        partialEvt.proposedThreadId = this.proposedThreadId;
      }

      this.emitComment(partialEvt);
    }

    // Reset local state
    this.draftComment = "";
    this.unfocusForm();
    if (this.resolvedToggle === true) {
      this.forceExpand = false;
    }

    // Emit send event after next render so that comment
    // has a chance to appear.
    dom.nextRender(() => {
      this.$emit("send");
    });
  }

  public emitComment(e: Partial<events.AddCommentEvent>) {
    const partial: Partial<Comment> = {};
    const setThreadResolution = e.resolve === undefined ? null : e.resolve;

    if (e.type === "new_thread_add_comment") {
      partial.setThreadResolution = setThreadResolution;
      partial.text = e.content || this.draftComment;
    }

    if (e.type === "reply_add_comment") {
      partial.threadId = e.threadId || undefined;
      partial.setThreadResolution = setThreadResolution;
      partial.text = e.content || this.draftComment;
    }

    // Add a temporary comment as latency compensation
    const timestamp = new Date().getTime();
    const comment: Comment = {
      id: `comment-${timestamp}`,
      threadId: `thread-${timestamp}`,
      setThreadResolution: false,
      draft: true,
      timestamp: timestamp,
      text: this.draftComment,
      username: this.authModule.assertUser.username,
      photoURL: this.authModule.assertUser.photoURL,
      // Add anything we get from the event
      ...partial
    };
    this.localComments.push(comment);
    this.$emit("comment", e);
  }

  get commentDisplays(): CommentDisplay[] {
    const remote = this.remoteComments.map(comment => {
      return { comment, sending: false };
    });
    const local = this.localComments.map(comment => {
      return { comment, sending: true };
    });

    return [...remote, ...local];
  }

  get comments(): Comment[] {
    return [...this.remoteComments, ...this.localComments];
  }

  public isMyComment(comment: Comment): boolean {
    return this.authModule.assertUser.username === comment.username;
  }

  public async editDraft(index: number) {
    const comment = this.comments[index];
    console.log(`editDraft: ${JSON.stringify(comment)}`);

    // Preference
    // 1) Explicit state on comment
    // 2) State before this comment
    const resolved =
      comment.setThreadResolution !== null
        ? comment.setThreadResolution
        : this.resolutionBefore(comment.id);

    this.editState = {
      comment,
      resolved,
      draft: comment.text
    };
  }

  public async deleteDraft(comment: Comment) {
    if (!confirm("Are you sure you want to delete this comment?")) {
      return;
    }

    console.log(`deleteDraft: ${JSON.stringify(comment)}`);
    await this.reviewModule.deleteDraftComment({ id: comment.id });
  }

  public onCancel() {
    // Reset draft and toggle
    this.draftComment = "";
    this.resolvedToggle = this.hasComments;

    if (this.hasComments) {
      this.unfocusForm();
    }
    this.$emit("cancel");
  }

  get locationId() {
    return this.threadId
      ? this.reviewModule.viewState.threadIdToLocationId[this.threadId]
      : null;
  }

  get showGoToLine() {
    if (this.threadIsDiscussion) {
      return false;
    }

    // An outdated thread may still show if the original context is presented
    //
    // TODO(polish): We can probably try to be a little more flexible here to
    // allow jumping to comments outside the visibly loaded diff.
    if (this.locationId) {
      return true;
    }

    return !this.outdated;
  }

  public goToLine() {
    this.$emit("goto");
  }

  public copyLink() {
    // This is empty on purpose ... maybe just to stop propagation? Honestly I forget.
  }

  public isNew(comment: Comment) {
    if (this.reviewModule.viewState.viewSinceTime === 0) {
      return false;
    }

    return (
      !comment.draft &&
      comment.username !== this.authModule.assertUser.username &&
      comment.timestamp >= this.reviewModule.viewState.viewSinceTime
    );
  }

  public formatTimestampShort(timestamp: number): string {
    return typeUtils.formatTimestampShort(timestamp);
  }

  public formatTimestampLong(timestamp: number): string {
    return typeUtils.formatTimestampLong(timestamp);
  }

  private focusForm() {
    // By waiting one tick to do this we avoid capturing the 'c' character
    // if this CommentThread was spawned by a hotkey.
    dom.nextRender(() => {
      // TODO(polish): Should probably define a RichTextArea API
      const field = this.$el.querySelector("textarea");
      if (field) {
        field.focus();
      }
    });
  }

  private unfocusForm() {
    dom.nextRender(() => {
      this.textFocus = false;
      const field = this.$el.querySelector("textarea");
      if (field) {
        field.blur();
      }
    });
  }
}
