
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import CommentThread from "@/components/elements/CommentThread.vue";
import { LangPair, SidePair, ThreadPair } from "../../model/review";
import { Side, DiffMode } from "../../../../shared/types";
import {
  RenderedChangePair,
  RenderedChange,
  FileMetadata,
  highlightContent,
  getLinesFromHighlightedContent,
  getLocationId
} from "../../plugins/diff";
import * as events from "../../plugins/events";
import { makeTopVisible, nextRender } from "../../plugins/dom";
import ReviewModule from "../../store/modules/review";
import { ChunkAPI } from "../api";
import { LINE_CLICK_EVENT } from "../../plugins/events";

interface DiffLineData {
  rendered: RenderedChangePair;
  index: number;
}

export type ChunkLineClickEvent = {
  side: Side;
  line: number;
};

@Component({
  components: {
    CommentThread
  }
})
export default class Chunk extends Vue implements ChunkAPI {
  @Prop() public locationIdPrefix!: string;
  @Prop() public pairs!: RenderedChangePair[];
  @Prop() public meta!: FileMetadata;
  @Prop() public mode!: DiffMode;
  @Prop() public index!: number;
  @Prop() public langs!: LangPair;
  @Prop() public lineNumberDigits!: number;
  @Prop({ default: false }) readOnly!: boolean;

  private reviewModule = getModule(ReviewModule, this.$store);

  // Map of lineKey --> ThreadPair
  public threads: Record<string, ThreadPair> = {};

  public draftingState: Record<string, boolean> = {};
  public activeLineIndex = -1;

  public selectLock: Side | null = null;

  mounted() {
    events.on(events.NEW_THREAD_EVENT, this.onNewThread);

    this.recalculateThreads();

    // TODO(polish): Need to download and highlight the entire file
    // to account for thinks like block comments that span a chunk.

    for (const l of this.lines) {
      if (l.rendered.left.type === "del" && l.rendered.right.type === "add") {
        // TODO(polish): Make this faster somehow
        nextRender(() => {
          this.highlightSublineDiff(l);
        });
      }
    }
  }

  destroyed() {
    events.off(events.NEW_THREAD_EVENT, this.onNewThread);
  }

  get highlightedLines(): SidePair<string[]> {
    return {
      left: this.highlightChunk("left").highlightedLines,
      right: this.highlightChunk("right").highlightedLines
    };
  }

  get lines(): DiffLineData[] {
    return this.pairs.map((rendered, lineIndex) => {
      return {
        rendered,
        index: this.index + lineIndex
      };
    });
  }

  @Watch("lines")
  public watchLines() {
    this.recalculateThreads();
  }

  private recalculateThreads() {
    for (let i = 0; i < this.lines.length; i++) {
      const l = this.lines[i];
      const key = this.getLineKey(l);
      const threadPair = this.getThreads(l);
      Vue.set(this.threads, key, threadPair);
    }
  }

  setActiveLine(lineIndex: number): void {
    // lineIndex === -1 is short for "deactivate"
    if (lineIndex < 0) {
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    }

    if (this.activeLineIndex >= 0) {
      this.hideLineShim(this.activeLineIndex);
    }

    this.activeLineIndex = lineIndex;

    if (this.activeLineIndex >= 0) {
      this.showLineShim(this.activeLineIndex);
    }
  }

  private hideLineShim(lineIndex: number) {
    const lineShim = this.getLineShim(lineIndex);
    if (lineShim) {
      lineShim.removeAttribute("active");
    }
  }

  private showLineShim(lineIndex: number) {
    const lineShim = this.getLineShim(lineIndex);
    if (lineShim) {
      lineShim.setAttribute("active", "true");
    }
  }

  /**
   * We use querySelector rather than Vue reactivity here because we want this
   * to be ultra fast and Vue is not super fast at reactive conditionals within
   * a v-for loop.
   */
  private getLineShim(lineIndex: number): HTMLElement | null {
    return this.$el.querySelector<HTMLElement>(
      `#${this.getLineKey(this.lines[lineIndex])} .shim`
    );
  }

  scrollToActiveLine(): void {
    if (this.activeLineIndex < 0) {
      return;
    }

    const l = this.lines[this.activeLineIndex];
    const lineEl = this.$el.querySelector(`#${this.getLineKey(l)}`);
    if (lineEl) {
      makeTopVisible(lineEl, 250);
    }
  }

  addComment(lineIndex: number): void {
    if (lineIndex < 0) {
      return;
    }

    const l = this.lines[lineIndex];
    if (!l.rendered.commentsEnabled || this.readOnly) {
      return;
    }

    const hasRight = !l.rendered.right.empty;
    if (hasRight) {
      this.setDrafting(l.rendered.right.number, "right", true);
    } else {
      this.setDrafting(l.rendered.left.number, "left", true);
    }
  }

  getIndex(): number {
    return this.index;
  }

  public getLocationIdForPair(pair: RenderedChangePair, side: Side) {
    const lineNumber = pair[side].number;
    return getLocationId(this.locationIdPrefix, lineNumber, side);
  }

  public getLineKey(l: DiffLineData): string {
    return `line-${l.rendered.left.number}-${l.rendered.right.number}`;
  }

  private onNewThread(event: events.NewThreadEvent) {
    const threadId = event.threadId;
    const locationId: string | undefined = this.reviewModule.viewState
      .threadIdToLocationId[threadId];

    const relevantLocationId =
      locationId && locationId.startsWith(this.locationIdPrefix);

    const relevantThreazdId = Object.values(this.threads).some(
      pair => pair.left?.id === threadId || pair.right?.id === threadId
    );

    if (relevantLocationId || relevantThreazdId) {
      this.recalculateThreads();
    }
  }

  public getThreads(line: DiffLineData): ThreadPair {
    const leftLocationId = this.getLocationIdForPair(line.rendered, "left");
    const rightLocationId = this.getLocationIdForPair(line.rendered, "right");

    const leftThreadId = this.reviewModule.viewState.locationIdToThreadId[
      leftLocationId
    ];
    const rightThreadId = this.reviewModule.viewState.locationIdToThreadId[
      rightLocationId
    ];

    if (!leftThreadId && !rightThreadId) {
      return {
        left: null,
        right: null
      };
    }

    // Only show visible threads
    const map = this.reviewModule.threadVisibilityMap;

    const left =
      leftThreadId && map[leftThreadId]
        ? this.reviewModule.threadById(leftThreadId)
        : null;

    const right =
      rightThreadId && map[rightThreadId]
        ? this.reviewModule.threadById(rightThreadId)
        : null;

    return {
      left,
      right
    };
  }

  public isDrafting(lineNumber: number, side: Side) {
    const key = `${lineNumber}-${side}`;
    return this.draftingState[key] || false;
  }

  public setDrafting(lineNumber: number, side: Side, val: boolean) {
    const key = `${lineNumber}-${side}`;
    Vue.set(this.draftingState, key, val);
  }

  public highlightChunk(side: Side) {
    const renderedChanges = this.pairs.map(p => p[side]);
    const content = renderedChanges.map(rc => rc.content).join("\n");

    const lang = this.langs[side];
    const highlighted = highlightContent(content, lang);

    const highlightedLines = getLinesFromHighlightedContent(highlighted);

    return { content, highlighted, highlightedLines };
  }

  /**
   * Hash a Prism-highlighted simple element.
   */
  private hashElement(el: Element) {
    return el.classList.toString() + "--" + el.textContent;
  }

  /**
   * After doing sub-line highlighting, find whitespace which is surrounded by
   * sub-line highlighted tokens and include it.
   */
  private highlightSubLineWhitespace(lineEl: Element, className: string) {
    const children = [...lineEl.children];

    // If a whitespace is surrounded by changed tokens, connect it
    for (let i = 1; i < children.length - 1; i++) {
      const mid = children[i];

      if (mid.classList.contains(className)) {
        continue;
      }

      if (!mid.textContent || mid.textContent.trim() !== "") {
        continue;
      }

      const prev = children[i - 1];
      const next = children[i + 1];

      if (
        prev.classList.contains(className) &&
        next.classList.contains(className)
      ) {
        mid.classList.add(className);
      }
    }
  }

  /**
   * We should not sub-line highlight all of the content in a line.
   */
  private removeExcessiveSublineHighlighting(
    lineEl: Element,
    className: string
  ) {
    const children = [...lineEl.children];
    const childrenWithContent = children.filter(
      e => e.textContent && e.textContent.trim() !== ""
    );

    const allChildrenHighlighted = childrenWithContent.every(e =>
      e.classList.contains(className)
    );
    if (allChildrenHighlighted) {
      for (const c of children) {
        c.classList.remove(className);
      }
    }
  }

  private highlightSublineDiff(l: DiffLineData) {
    const cl = this.$el.querySelector(`#cl-${l.index}`);
    const cr = this.$el.querySelector(`#cr-${l.index}`);
    if (!cl || !cr) {
      return;
    }

    const leftChildren = [...cl.children].map(el => ({
      el,
      hash: this.hashElement(el)
    }));

    const rightChildren = [...cr.children].map(el => ({
      el,
      hash: this.hashElement(el)
    }));

    const leftChildrenHashes = new Set(leftChildren.map(c => c.hash));
    const rightChildrenHashes = new Set(rightChildren.map(c => c.hash));

    for (const c of leftChildren) {
      if (!rightChildrenHashes.has(c.hash)) {
        c.el.classList.add("bg-code-del-200");
      }
    }

    for (const c of rightChildren) {
      if (!leftChildrenHashes.has(c.hash)) {
        c.el.classList.add("bg-code-add-200");
      }
    }

    this.removeExcessiveSublineHighlighting(cl, "bg-code-del-200");
    this.removeExcessiveSublineHighlighting(cr, "bg-code-add-200");

    this.highlightSubLineWhitespace(cl, "bg-code-del-200");
    this.highlightSubLineWhitespace(cr, "bg-code-add-200");
  }

  public onCommentEvent(l: DiffLineData, e: Partial<events.AddCommentEvent>) {
    console.log(`Chunk#onCommentEvent: ${JSON.stringify(e)}`);

    // TODO(polish): Now that there is no more DiffLine should we change the balance of data?
    if (e.type === "new_thread_add_comment") {
      if (e.side) {
        // We add the lineContent here
        e.lineContent = l.rendered[e.side].content;
      }
    }

    this.$emit("comment", e);
  }

  public setSelectLock(side: Side) {
    this.selectLock = side;
  }

  public onCommentCancel(lineNumber: number, side: Side) {
    this.setDrafting(lineNumber, side, false);
  }

  public onCommentSettled(lineNumber: number, side: Side) {
    this.setDrafting(lineNumber, side, false);
  }

  public showComments(lineIndex: number, side: Side) {
    const line = this.lines[lineIndex];
    const threadPair = this.threads[this.getLineKey(line)];

    return (
      this.isDrafting(line.rendered[side].number, side) ||
      (threadPair && threadPair[side] != null)
    );
  }

  public renderCommentButton(lineIndex: number, side: Side) {
    const l = this.lines[lineIndex];
    if (!l.rendered.commentsEnabled || this.readOnly) {
      return false;
    }

    // For 'normal' lines the comment must always be left on the right side.
    if (side === "left" && l.rendered.left.type === "normal") {
      return false;
    }

    // Can't comment on a blank space
    if (l.rendered[side].empty) {
      return false;
    }

    // If we're showing the thread, don't show the button
    return !this.showComments(lineIndex, side);
  }

  public stripNewlines(str: string): string {
    return str.replace(/\n/g, "");
  }

  public getThreadId(l: DiffLineData, side: Side): string | null {
    const threadPair = this.threads[this.getLineKey(l)];
    if (!threadPair) {
      return null;
    }
    const thread = threadPair[side];
    return thread === null ? null : thread.id;
  }

  public showLeft(l: DiffLineData) {
    if (this.mode === "split") {
      return true;
    }
    return !(l.rendered.left.empty || l.rendered.left.type === "normal");
  }

  public showRight(l: DiffLineData) {
    if (this.mode === "split") {
      return true;
    }
    return !l.rendered.right.empty;
  }

  public sideClassNames(l: DiffLineData, side: Side) {
    const classes: string[] = [];
    const change = l.rendered[side];

    // Background
    classes.push(this.bgClass(change));

    // Full or half width
    if (this.mode === "unified") {
      classes.push("w-full");
    } else {
      classes.push("w-1/2");
    }

    return classes;
  }

  public commentClassNames() {
    if (this.mode === "unified") {
      return ["w-full m-1 xl:w-2/3 max-w-screen-xl"];
    } else {
      return ["w-full"];
    }
  }

  public bgClass(change: RenderedChange): string {
    switch (change.type) {
      case "del":
        return "bg-code-del-100";
      case "add":
        return "bg-code-add-100";
      default:
        if (change.empty) {
          return "bg-app-2";
        }
        return "bg-app-1";
    }
  }

  @Emit(LINE_CLICK_EVENT)
  public onLineNumberClicked(side: Side, line: number) {
    return {
      side,
      line
    };
  }

  public lineNumberClassNames(change: RenderedChange): string[] {
    const base = this.readOnly ? [] : ["cursor-pointer"];

    switch (change.type) {
      case "add":
        return [
          ...base,
          "bg-code-add-200",
          "dark:text-green-300 text-green-500"
        ];
      case "del":
        return [...base, "bg-code-del-200", "dark:text-red-300 text-red-500"];
      default:
        return [...base, "text-app-dim"];
    }
  }

  public lineNumberString(change: RenderedChange): string {
    const num = change.empty ? "" : `${change.number}`;

    // Pad with leading spaces
    return num.padStart(this.lineNumberDigits, " ");
  }
}
