
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SplitButton extends Vue {
  // Use with v-model
  @Prop() value!: string;

  @Prop() keys!: string[];
  @Prop() values!: string[];

  public classNames(key: string) {
    if (this.value === key) {
      return ["bg-app-3", "shadow-inner", "text-brand-400"];
    } else {
      return ["bg-app-5", "hover:bg-app-4"];
    }
  }

  public onSelected(key: string) {
    this.$emit("input", key);
  }
}
