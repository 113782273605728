
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Avatar extends Vue {
  @Prop() username!: string;
  @Prop() size!: string;
}
