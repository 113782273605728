import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import {
  doc,
  collection,
  query,
  onSnapshot,
  FirestoreDataConverter,
  getDoc,
  setDoc,
  writeBatch,
  deleteField,
  updateDoc,
  WriteBatch,
  DocumentData
} from "firebase/firestore";

import { newRandomId } from "@/model/review";
import {
  Comment,
  CommentUser,
  Thread,
  Review,
  ReviewMetadata,
  ThreadArgs,
  ReviewStatus,
  ReviewState,
  ReviewIdentifier,
  ReviewHistory,
  Repo,
  ReviewUserData,
  RemoveReviewerAction,
  AddReviewerAction,
  RemoveAssigneeAction,
  AddAssigneeAction,
  RepoBranchPair,
  RemoveCcAction,
  AddCcAction,
  DiffMode,
  DiffServer
} from "../../../../shared/types";
import {
  calculateReviewStatus,
  setAdd,
  setRemove
} from "../../../../shared/typeUtils";
import * as events from "../../plugins/events";
import * as pagevis from "../../plugins/pagevis";
import { firestore } from "../../plugins/firebase";
import { addReviewHistory } from "../../plugins/data";
import { logError } from "../../plugins/sentry";
import {
  repoPath,
  reviewPath,
  threadsPath,
  commentsPath,
  reviewHistoriesPath,
  reviewUserDatasPath
} from "../../../../shared/database";
import { Latch, MultipleError } from "../../../../shared/asyncUtils";
import { CommitStatus, Github } from "../../../../shared/github";
import { freezeArray } from "../../../../shared/freeze";
import parseDiff from "parse-diff";
import { LocalSettings } from "@/plugins/localSettings";
import {
  ChunkData,
  getDiff,
  getThreadLocationMap,
  PullRequestChange,
  RenderedChangePair,
  RenderedPullRequest,
  renderLoadedLineChange,
  renderPullRequest
} from "@/plugins/diff";
import { FLAGS, getBooleanFlag } from "@/plugins/flags";

const deepEqual = require("fast-deep-equal");

type Listener = () => void;

export interface CommitData {
  sha: string;
  commit: {
    message: string;
    pushedDate: string | null;
    committedDate: string | null;
  };
  isMerge: boolean;
}

export interface ViewState {
  userCanWrite: boolean;
  dirty: boolean;

  /** Base SHA */
  base: string;

  /** Head SHA */
  head: string;

  /** Raw diffs from Git. */
  diffs: parseDiff.File[];

  /** "Rendered" pull request changes */
  prChanges: RenderedPullRequest;

  /** Map from locationId to threadId */
  locationIdToThreadId: Record<string, string>;

  /** Map from threadId to locationId */
  threadIdToLocationId: Record<string, string>;

  commits: CommitData[];
  visibleCommits: CommitData[];

  /** View since / until */
  viewSinceTime: number;
  viewUntilTime: number;

  sortOrder: string;

  /** Body with all images rendered properly */
  bodyHTML: string;
}

const SortByTimestamp = function(a: Comment, b: Comment) {
  return a.timestamp - b.timestamp;
};

function getDefaultSortOrder(): string {
  return LocalSettings.getOrDefault(LocalSettings.KEY_SORT_ORDER, "diff-desc");
}

function getEmptyViewState(): ViewState {
  return {
    userCanWrite: false,
    dirty: false,
    base: "unknown",
    head: "unknown",
    diffs: [],
    prChanges: {},
    locationIdToThreadId: {},
    threadIdToLocationId: {},
    commits: [],
    visibleCommits: [],
    viewSinceTime: 0,
    viewUntilTime: 0,
    sortOrder: getDefaultSortOrder(),
    bodyHTML: ""
  };
}

function getEmptyRepo(): Repo {
  return {
    owner: "unknown",
    name: "unknown",
    private: true
  };
}

function getEmptyReviewUserData(): ReviewUserData {
  return {
    filesReviewed: {}
  };
}

function getEmptyCommitStatus(): CommitStatus {
  return {
    merge: {
      mergeable: true
    },
    checks: []
  };
}

function getEmptyReview(): Review {
  return {
    metadata: {
      owner: "unknown",
      repo: "unknown",
      number: 0,
      author: "unknown",
      title: "unknown",
      body: "unknown",
      base: {
        sha: "unknown",
        ref: "unknown",
        label: "unknown:unknown",
        user: {
          login: "unknown"
        }
      },
      head: {
        sha: "unknown",
        ref: "unknown",
        label: "unknown:unknown",
        user: {
          login: "unknown"
        }
      },
      updated_at: new Date().getTime()
    },
    state: {
      status: ReviewStatus.NEEDS_REVIEW,
      closed: false,
      reviewers: [],
      approvers: [],
      assignees: [],
      ccs: [],
      unresolved: 0,
      last_comment: 0
    }
  };
}

function getDiffServer(
  review: Review,
  viewState: Partial<ViewState>,
  hasHeadOrBaseOverride: boolean
): DiffServer {
  // We ignore the user's diff server selection and revert to GitHub when:
  // 1) When the Remote Config flag is disabled
  // 2) When getting the true 'base' diff for a PR
  // 3) When the diff is across forks
  // 4) When the PR is merged (because the branch may be deleted)
  const enableDiffServer = getBooleanFlag(FLAGS.ENABLE_DIFF_SERVER);

  const viewingIntermediateDiff =
    hasHeadOrBaseOverride ||
    (viewState.visibleCommits &&
      viewState.commits &&
      viewState.visibleCommits.length < viewState.commits.length);

  // TODO(polish): Make the diff server work for forks!
  const isFork =
    review.metadata.base.user.login !== review.metadata.head.user.login;

  // TODO(polish): Eventually remove this distinction!
  const isPrMerged =
    review.state.status === ReviewStatus.CLOSED_MERGED ||
    review.state.status === ReviewStatus.CLOSED_UNMERGED;

  if (enableDiffServer && viewingIntermediateDiff && !isFork && !isPrMerged) {
    const diffServer: DiffServer = LocalSettings.getOrDefault(
      LocalSettings.KEY_DIFF_SERVER,
      "github"
    );

    return diffServer;
  }

  return "github";
}

@Module({
  name: "review"
})
export default class ReviewModule extends VuexModule {
  // Warning: if you change this block, change the matching block in stopListening()
  // START_BLOCK
  public listeners: Listener[] = [];

  public repo: Repo = getEmptyRepo();
  public review: Review = getEmptyReview();
  public reviewUserData: ReviewUserData = getEmptyReviewUserData();
  public commitStatus: CommitStatus = getEmptyCommitStatus();
  public viewState: ViewState = getEmptyViewState();

  public username = "unknown";
  public reviewLoaded = false;
  public estimatedStatus = ReviewStatus.NEEDS_REVIEW;

  public threads: Thread[] = [];
  public comments: Comment[] = [];
  public histories: ReviewHistory[] = [];
  // END_BLOCK

  static forceConverter<T extends DocumentData>(): FirestoreDataConverter<T> {
    return {
      toFirestore: (modelObject: T) => {
        return modelObject;
      },
      fromFirestore: (snapshot, _) => {
        return snapshot.data() as T;
      }
    };
  }

  // TODO(polish): Ditch all of these static methods
  static repoRef(opts: ReviewIdentifier) {
    return doc(firestore(), repoPath(opts));
  }

  static reviewRef(opts: ReviewIdentifier) {
    return doc(firestore(), reviewPath(opts)).withConverter(
      this.forceConverter<Review>()
    );
  }

  static threadsRef(opts: ReviewIdentifier) {
    return collection(firestore(), threadsPath(opts)).withConverter(
      this.forceConverter<Thread>()
    );
  }

  static commentsRef(opts: ReviewIdentifier) {
    return collection(firestore(), commentsPath(opts)).withConverter(
      this.forceConverter<Comment>()
    );
  }

  static historiesRef(opts: ReviewIdentifier) {
    return collection(firestore(), reviewHistoriesPath(opts)).withConverter(
      this.forceConverter<ReviewHistory>()
    );
  }

  get mode() {
    const mode: DiffMode = LocalSettings.getOrDefault(
      LocalSettings.KEY_DIFF_MODE,
      "split"
    );

    return mode;
  }

  get drafts() {
    return this.comments.filter(x => x.draft);
  }

  get commentsByThread() {
    return (threadId: string) => {
      return this.comments
        .filter(x => x.threadId === threadId)
        .sort(SortByTimestamp);
    };
  }

  get visibleCommentsByThread() {
    return (threadId: string, username: string) => {
      return this.comments
        .filter(x => x.threadId === threadId)
        .filter(x => !x.draft || x.username === username)
        .sort(SortByTimestamp);
    };
  }

  get threadById() {
    return (threadId: string) => {
      return this.threads.find(x => x.id === threadId) || null;
    };
  }

  get threadVisibilityMap() {
    // First filter out all drafts by other people
    let res = this.threads.filter(
      t => !t.draft || t.username === this.username
    );

    // Next, filter on the time window
    if (this.hasViewWindow) {
      const viewSince = this.viewState.viewSinceTime;
      const viewUntil =
        this.viewState.viewUntilTime > 0
          ? this.viewState.viewUntilTime
          : Number.MAX_SAFE_INTEGER;

      // Find all histories which are in the window
      const historiesSince = this.histories.filter(
        h => h.timestamp >= viewSince && h.timestamp <= viewUntil
      );

      // Find all threads which are involved in those histories
      const threadIdsInvolved = historiesSince.flatMap(h => {
        const threadIds: string[] = [];
        for (const a of h.actions) {
          if (
            a.type === "add_thread" ||
            a.type === "add_comment" ||
            a.type === "resolve_thread"
          ) {
            threadIds.push(a.threadId);
          }
        }

        return threadIds;
      });

      const threadIdsInvolvedSet = new Set(threadIdsInvolved);
      res = res.filter(t => threadIdsInvolvedSet.has(t.id));
    }

    // Make sure our drafts are included (even though they are not yet part of
    // any review history)
    const ourDraftThreads = this.threads.filter(
      t => t.draft && t.username === this.username
    );

    const threadsToConsider = [...res, ...ourDraftThreads];
    const map: Record<string, boolean> = {};
    for (const t of threadsToConsider) {
      map[t.id] = true;
    }

    return map;
  }

  get visibleThreads() {
    const map = this.threadVisibilityMap;
    return this.threads.filter(t => !!map[t.id]);
  }

  get hasViewWindow() {
    return this.viewState.viewSinceTime > 0 || this.viewState.viewUntilTime > 0;
  }

  get threadsByFile() {
    return (file: string) => {
      return this.visibleThreads.filter(x =>
        [x.currentArgs, x.lastValidArgs, x.originalArgs].some(
          args => args && args.file === file
        )
      );
    };
  }

  get repoBranchPair(): RepoBranchPair {
    return {
      owner: this.review.metadata.owner,
      repo: this.review.metadata.repo,
      base: this.viewState.base,
      head: this.viewState.head
    };
  }

  get countResolutions() {
    const draftThreads = this.threads
      .filter(t => t.draft)
      .filter(t => t.username === this.username);

    const draftComments = this.drafts.filter(c => c.username === this.username);

    // Map from threadId to number. A number > 1 means unresolved, a number < 0 means resolved.
    const threadDeltas: Record<string, number> = {};
    for (const t of draftThreads) {
      threadDeltas[t.id] = (threadDeltas[t.id] || 0) + 1;
    }

    for (const c of draftComments) {
      if (c.setThreadResolution === false) {
        threadDeltas[c.threadId] = (threadDeltas[c.threadId] || 0) + 1;
      }

      if (c.setThreadResolution === true) {
        threadDeltas[c.threadId] = (threadDeltas[c.threadId] || 0) - 1;
      }
    }

    // All new threads start as unresolved, but if the first comment
    // resolve it it won't show up in either count here.
    let newUnresolved = 0;
    let newResolved = 0;

    Object.values(threadDeltas).forEach(d => {
      if (d > 0) {
        newUnresolved++;
      } else if (d < 0) {
        newResolved++;
      }
    });

    const delta = newUnresolved - newResolved;
    return {
      newUnresolved,
      newResolved,
      delta
    };
  }

  @Action({ rawError: true })
  public async loadRepo(opts: { id: ReviewIdentifier }) {
    const ref = doc(firestore(), repoPath(opts.id));
    const snap = await getDoc(ref);
    const data = snap.data();

    if (data) {
      this.context.commit("setRepo", data as Repo);
    } else {
      throw new Error(`No such repository: ${opts.id.owner}/${opts.id.repo}`);
    }
  }

  @Action({ rawError: true })
  public async initializeViewState(opts: {
    github: Github;
    review: Review;
    username: string;
    viewState: Partial<ViewState>;
  }) {
    const { review } = opts;

    const base = opts.viewState.base || review.metadata.base.sha;
    const head = opts.viewState.head || review.metadata.head.sha;

    const hasHeadOrBaseOverride =
      !!opts.viewState.base || !!opts.viewState.head;

    const baseLabel = `${this.review.metadata.base.user.login}:${base}`;
    const headLabel = `${this.review.metadata.head.user.login}:${head}`;

    const commitNodes = await opts.github.listPullRequestCommits(
      review.metadata.owner,
      review.metadata.repo,
      review.metadata.number
    );

    // Load rendered body HTML
    await this.reloadBodyHTML(opts);

    // TODO(polish): We can probably get rid of the CommitData type
    const commits: CommitData[] = commitNodes.map(c => {
      return {
        sha: c.commit.oid,
        commit: c.commit,
        isMerge: c.commit.parents.totalCount > 1
      };
    });

    const diffServer = getDiffServer(
      this.review,
      opts.viewState,
      hasHeadOrBaseOverride
    );
    console.log({
      diffServer
    });

    const diffsPromise = getDiff(
      review.metadata.owner,
      review.metadata.repo,
      baseLabel,
      headLabel,
      this.review.metadata.head.ref,
      commits.length + 1,
      {
        github: opts.github,
        diffServer
      }
    );

    const permissionPromise = opts.github.getPermissionForRepo(
      review.metadata.owner,
      review.metadata.repo,
      opts.username
    );

    // Execute these three requests in parallel for speed
    const latch = new Latch([diffsPromise, permissionPromise]);

    // Swallow errors and log a warning
    try {
      await latch.wait();
    } catch (e) {
      const me = e as MultipleError;
      for (const oe of me.errors) {
        logError(oe, "Error fetching diffs, commits, or permissions");
      }

      events.emit(events.CUSTOM_ERROR_EVENT, {
        original: e,
        message:
          "Sorry! There was an error accessing some data for this Pull Request. Consider refreshing the page or signing out and signing in again."
      });
    }

    const diffs = latch.getResult(diffsPromise) || [];

    const permission = latch.getResult(permissionPromise) || "none";
    const userCanWrite = permission === "write" || permission === "admin";

    // Add the base commit at the beginning
    commits.unshift({
      sha: review.metadata.base.sha,
      commit: {
        message: review.metadata.base.ref,
        committedDate: null,
        pushedDate: null
      },
      isMerge: false
    });

    // Diff sort order
    const sortOrder = opts.viewState.sortOrder || getDefaultSortOrder();

    // Render the pr changes
    const prChanges = renderPullRequest(diffs, this.mode);

    // TODO: Set the viewSinceTime and viewUntilTime
    const baseIndex = commits.findIndex(c => c.sha === base);
    const headIndex = commits.findIndex(c => c.sha === head);
    console.log({
      baseIndex,
      headIndex,
      length: commits.length
    });

    // Set up the view state
    const viewState: ViewState = {
      userCanWrite,
      dirty: false,
      base,
      head,
      diffs,
      prChanges,
      locationIdToThreadId: {},
      threadIdToLocationId: {},
      commits,
      visibleCommits: commits,
      viewSinceTime: 0,
      viewUntilTime: 0,
      sortOrder,
      bodyHTML: this.viewState.bodyHTML || ""
    };
    this.context.commit("setViewState", viewState);

    // TODO(polish): We really should not have to do this separately, all this does
    // is make sure that visibleCommits field batches the base and head
    this.context.commit("setBaseAndHead", { base: base, head: head });
  }

  @Action({ rawError: true })
  public async loadDiffs(opts: {
    github: Github;
    base: string;
    head: string;
    username: string;
  }) {
    this.context.commit("setBaseAndHead", {
      base: opts.base,
      head: opts.head
    });

    const baseLabel = `${this.review.metadata.base.user.login}:${opts.base}`;
    const headLabel = `${this.review.metadata.head.user.login}:${opts.head}`;

    const diffServer = getDiffServer(
      this.review,
      this.viewState,
      /* hasHeadOrBaseOverride= */ false
    );
    console.log({
      diffServer
    });

    // Set to blank before reloading
    this.context.commit("setDiffs", []);
    this.context.commit("setPrChanges", {});

    const diffs = await getDiff(
      this.review.metadata.owner,
      this.review.metadata.repo,
      baseLabel,
      headLabel,
      this.review.metadata.head.ref,
      this.viewState.commits.length + 1,
      {
        github: opts.github,
        diffServer
      }
    );

    this.context.commit("setDiffs", diffs);

    const mode: DiffMode = LocalSettings.getOrDefault(
      LocalSettings.KEY_DIFF_MODE,
      "split"
    );
    const prChanges = renderPullRequest(diffs, mode);
    this.context.commit("setPrChanges", prChanges);

    // Recalculate where threads should be shown
    this.context.commit("recalculateThreadLocations", {
      username: opts.username,
      prChanges
    });
  }

  @Action({ rawError: true })
  public async initializeReview(opts: {
    github: Github;
    username: string;
    id: ReviewIdentifier;
    viewState: Partial<ViewState>;
  }) {
    console.log("reviewModule#initializeReview");
    this.context.commit("stopListening");
    this.context.commit("setReviewLoaded", false);
    this.context.commit("setUsername", opts.username);

    const latch = new Latch();

    // Load the review once to get started
    const reviewSnap = await getDoc(ReviewModule.reviewRef(opts.id));
    if (!reviewSnap.exists()) {
      console.warn(
        `No such review: ${opts.id.owner}/${opts.id.repo}/${opts.id.number}`
      );
      return;
    }

    const review = reviewSnap.data()!;
    this.context.commit("setReviewMetadata", review.metadata);
    this.context.commit("setReviewState", review.state);

    // Initialize ViewState (async)
    const viewStatePromise = this.initializeViewState({ ...opts, review });
    latch.incrementPromise(viewStatePromise);

    latch.increment();
    const reviewUnsub = onSnapshot(
      ReviewModule.reviewRef(opts.id),
      { includeMetadataChanges: true },
      snap => {
        console.log(
          `review#onSnapshot: pending=${snap.metadata.hasPendingWrites}`
        );
        const review = snap.data();
        this.context.commit("setReviewLoaded", snap.exists());

        if (review) {
          const headsEqual = deepEqual(
            review.metadata.head,
            this.review.metadata.head
          );
          const basesEqual = deepEqual(
            review.metadata.base,
            this.review.metadata.base
          );

          if (!(headsEqual && basesEqual)) {
            // The PR head or base has changed, we should ask the user to review
            this.context.commit("setDirty", true);
          }

          this.context.commit("setReviewMetadata", review.metadata);

          // For the review state we actually prefer our guesses
          if (!snap.metadata.hasPendingWrites) {
            this.context.commit("setReviewState", review.state);
          }
          this.context.commit("calculateReviewStatus");
        }

        latch.decrement();
      }
    );
    this.context.commit("addListener", reviewUnsub);

    latch.increment();
    const threadsUnsub = onSnapshot(ReviewModule.threadsRef(opts.id), snap => {
      console.log("threads#onSnapshot", snap.size);
      const threads = snap.docs.map(doc => doc.data());
      this.context.commit("setThreads", threads);
      this.context.commit("calculateReviewStatus");

      this.context.commit("recalculateThreadLocations", {
        username: opts.username
      });

      snap.docChanges().forEach(chg => {
        const thread = chg.doc.data();
        events.emit(events.NEW_THREAD_EVENT, { threadId: thread.id });
      });

      latch.decrement();
    });
    this.context.commit("addListener", threadsUnsub);

    latch.increment();
    const commentsUnsub = onSnapshot(
      ReviewModule.commentsRef(opts.id),
      snap => {
        console.log("comments#onSnapshot", snap.size);
        const comments = snap.docs.map(doc => doc.data());
        this.context.commit("setComments", comments);

        snap.docChanges().forEach(chg => {
          const comment = chg.doc.data();
          events.emit(events.NEW_COMMENT_EVENT, { threadId: comment.threadId });
        });

        latch.decrement();
      }
    );
    this.context.commit("addListener", commentsUnsub);

    latch.increment();
    const historyUnsub = onSnapshot(
      ReviewModule.historiesRef(opts.id),
      snap => {
        console.log("history#onSnapshot", snap.size);
        const histories = snap.docs.map(doc => doc.data());
        this.context.commit("setHistories", histories);

        latch.decrement();
      }
    );
    this.context.commit("addListener", historyUnsub);

    latch.increment();
    const userDataUnsub = onSnapshot(
      doc(collection(firestore(), reviewUserDatasPath(opts.id)), opts.username),
      snap => {
        console.log("reviewUserData#onSnapshot");
        if (snap.exists()) {
          this.context.commit(
            "setReviewUserData",
            snap.data() as ReviewUserData
          );
        }
        latch.decrement();
      }
    );
    this.context.commit("addListener", userDataUnsub);

    const fetchCommitStatus = async () => {
      const { owner, repo, number } = this.review.metadata;
      const ref = this.review.metadata.head.sha;
      const commitStatus = await opts.github.getCommitStatusAndChecks(
        owner,
        repo,
        number,
        ref
      );

      this.context.commit("setCommitStatus", commitStatus);
    };

    // Fetch the commit status once
    const firstStatusPromise = fetchCommitStatus();
    latch.incrementPromise(firstStatusPromise);

    // Repeatedly query the status when the page is visible
    const interval = setInterval(() => {
      if (pagevis.isVisible()) {
        fetchCommitStatus();
      }
    }, 30 * 1000);

    // Stop doing this on clear
    const intervalListener = () => clearInterval(interval);
    this.context.commit("addListener", intervalListener);

    return latch.wait();
  }

  @Mutation
  public recalculateThreadLocations(opts: {
    username?: string;
    prChanges?: RenderedPullRequest;
  }) {
    const username = opts.username ?? this.username;

    // TODO: This logic is duplicated
    const threads = this.threads.filter(t => {
      // Only show our own drafts
      return !t.draft || t.username === username;
    });

    const prChanges = opts.prChanges || this.viewState.prChanges;
    const visibleShas = this.viewState.visibleCommits.map(c => c.sha);

    // Get the forward map
    const locationIdToThreadId = getThreadLocationMap(
      prChanges,
      threads,
      visibleShas
    );

    // Reverse the map
    const threadIdToLocationId: Record<string, string> = {};
    Object.entries(locationIdToThreadId).forEach(
      e => (threadIdToLocationId[e[1]] = e[0])
    );

    // TODO: Use Vue.set to do this more surgically
    this.viewState.locationIdToThreadId = locationIdToThreadId;
    this.viewState.threadIdToLocationId = threadIdToLocationId;
  }

  @Mutation
  public addListener(listener: Listener) {
    this.listeners.push(listener);
  }

  @Mutation
  public stopListening() {
    console.log("review#stopListening()");
    for (const l of this.listeners) {
      l();
    }

    this.listeners = [];

    // Warning: if you change this block, change the matching block up top
    // START_BLOCK
    this.repo = getEmptyRepo();
    this.review = getEmptyReview();
    this.reviewUserData = getEmptyReviewUserData();
    this.commitStatus = getEmptyCommitStatus();
    this.viewState = getEmptyViewState();

    this.username = "unknown";
    this.reviewLoaded = false;
    this.estimatedStatus = ReviewStatus.NEEDS_REVIEW;

    this.threads = [];
    this.comments = [];
    this.histories = [];
    // END_BLOCK
  }

  @Mutation
  public calculateReviewStatus() {
    if (this.review.state.closed) {
      this.estimatedStatus = this.review.state.status;
      return;
    }

    // Use server state but update unresolved threads
    const unresolved = this.threads.filter(x => !x.draft && !x.resolved).length;
    const newState = {
      ...this.review.state,
      unresolved
    };

    // Estimate review status
    this.estimatedStatus = calculateReviewStatus(
      this.review.metadata,
      newState
    );
    if (this.estimatedStatus !== this.review.state.status) {
      console.log(
        `calculateReviewStatus(${JSON.stringify(newState)}): ${
          this.review.state.status
        } --> ${this.estimatedStatus}`
      );
    }
  }

  @Action({ rawError: true })
  public addLinesToChunk(opts: {
    changeKey: string;
    chunkIndex: number;
    addTo: "start" | "end";
    leftLines: string[];
    rightLines: string[];
    readOnly: boolean;
    username: string;
  }) {
    const { changeKey, leftLines, rightLines, addTo, chunkIndex } = opts;

    const change = this.viewState.prChanges[changeKey];
    const chunks = change.data;

    // Loading an unloaded file, add an empty chunk
    if (opts.chunkIndex === 0 && change.data.length === 0) {
      const emptyChunkData: ChunkData = {
        chunk: {
          content: "",
          oldStart: 1,
          oldLines: 0,
          newStart: 1,
          newLines: 0,
          changes: []
        },
        pairs: []
      };

      chunks.push(emptyChunkData);
    }

    const chunk = chunks[chunkIndex].chunk;
    const leftStart =
      addTo === "start"
        ? chunk.oldStart - leftLines.length
        : chunk.oldStart + chunk.oldLines;

    const rightStart =
      addTo === "start"
        ? chunk.newStart - rightLines.length
        : chunk.newStart + chunk.newLines;

    const pairs: RenderedChangePair[] = [];
    for (let i = 0; i < leftLines.length; i++) {
      pairs.push({
        left: renderLoadedLineChange(leftStart + i, leftLines[i]),
        right: renderLoadedLineChange(rightStart + i, rightLines[i]),
        commentsEnabled: !opts.readOnly
      });
    }

    // Freeze pairs and add them to the start of the chunk
    const frozen = freezeArray(pairs);

    if (addTo === "start") {
      // When adding to the start we have to change the start points
      chunks[chunkIndex].pairs.unshift(...frozen);
      chunk.oldStart = chunk.oldStart - leftLines.length;
      chunk.newStart = chunk.newStart - rightLines.length;
    } else {
      chunks[chunkIndex].pairs.push(...frozen);
    }

    // Adding to the start or the end changes the number of lines
    chunk.oldLines = chunk.oldLines + leftLines.length;
    chunk.newLines = chunk.newLines + rightLines.length;

    // Re-set the whole change
    this.context.commit("setPrChange", { changeKey, change });

    // Recalculate visible threads
    this.context.commit("recalculateThreadLocations", {
      username: opts.username
    });
  }

  /**
   * GitHub now uses private URLs for images on private repos. This hack uses the body HTML from
   * the GraphQL API to get the public image links and then swaps them:
   * https://github.blog/changelog/2023-05-09-more-secure-private-attachments/
   */
  get renderableBody() {
    // Two known forms, the thing we want is the asset id (Group 3)
    // Ex: https://github.com/user-attachments/assets/e1fd154a-f949-42a4-886b-6e52c3cbd778
    // Ex: https://github.com/owner/repo/assets/12345/e1fd154a-f949-42a4-886b-6e52c3cbd778
    const assetRe = /https:\/\/github.com\/(.+?)\/assets\/([0-9]+\/)?([a-z0-9-]+)/g;

    // Groups: link
    const srcRe = /src="(.+?)"/g;

    const originalBody = this.review.metadata.body;
    const renderedBody = this.viewState.bodyHTML;

    const privateLinkMatches = originalBody?.matchAll(assetRe);
    if (!privateLinkMatches) {
      return originalBody;
    }

    const srcMatches = renderedBody.matchAll(srcRe);
    const srcLinks = [...srcMatches].map(m => m[1]);

    // We identify the public link that maps to the private link by its ID (4th group)
    let newBody = originalBody;
    for (const match of privateLinkMatches) {
      const replacement = srcLinks.find(l => l.includes(match[3]));
      if (replacement) {
        newBody = newBody?.replaceAll(match[0], replacement) || "";
      }
    }

    return newBody;
  }

  @Mutation
  public setBodyHTML(bodyHTML: string) {
    this.viewState.bodyHTML = bodyHTML;
  }

  @Action({ rawError: true })
  public async reloadBodyHTML(opts: { github: Github }) {
    const { owner, repo, number } = this.review.metadata;
    const bodyHTML = await opts.github.getPullRequestBodyHTML(
      owner,
      repo,
      number
    );
    this.context.commit(
      "setBodyHTML",
      bodyHTML.repository.pullRequest.bodyHTML
    );
  }

  @Mutation
  public setReviewLoaded(reviewLoaded: boolean) {
    this.reviewLoaded = reviewLoaded;
  }

  @Mutation
  public setUsername(username: string) {
    this.username = username;
  }

  @Mutation
  public setReviewMetadata(metadata: ReviewMetadata) {
    this.review.metadata = metadata;
  }

  @Mutation
  public setReviewState(state: ReviewState) {
    this.review.state = state;
  }

  @Mutation
  public setReviewUserData(reviewUserData: ReviewUserData) {
    this.reviewUserData = reviewUserData;
  }

  @Mutation
  public setViewState(viewState: ViewState) {
    this.viewState = viewState;
    this.viewState.diffs = freezeArray(this.viewState.diffs);
    this.viewState.commits = freezeArray(this.viewState.commits);
  }

  @Mutation
  public setRepo(repo: Repo) {
    this.repo = repo;
  }

  @Mutation
  public setDirty(dirty: boolean) {
    this.viewState.dirty = dirty;
  }

  @Mutation
  public setDiffs(diffs: parseDiff.File[]) {
    this.viewState.diffs = freezeArray(diffs);
  }

  @Mutation
  public setPrChange(opts: { changeKey: string; change: PullRequestChange }) {
    Vue.set(this.viewState.prChanges, opts.changeKey, opts.change);
  }

  @Mutation
  public setPrChanges(prChanges: RenderedPullRequest) {
    this.viewState.prChanges = prChanges;
  }

  @Mutation
  public setThreads(threads: Thread[]) {
    if (!this.viewState.dirty) {
      this.threads = threads;
      return;
    }

    // When the page needs a refresh we have to surgically
    // update the threads by leaving the "args" as-is but replacing
    // the rest
    console.warn("Review is dirty, attempting best-effort thread update.");
    const newThreads = threads.map(next => {
      const prev = this.threads.find(x => x.id === next.id);
      if (!prev) {
        return next;
      }

      return {
        ...next,
        currentArgs: prev.currentArgs,
        originalArgs: prev.originalArgs
      };
    });

    this.threads = newThreads;
  }

  @Mutation
  public setComments(comments: Comment[]) {
    this.comments = comments;
  }

  @Mutation
  public setHistories(histories: ReviewHistory[]) {
    this.histories = histories;
  }

  @Action({ rawError: true })
  public addReviewer(opts: { login: string; approved: boolean }) {
    if (this.review.state.reviewers.includes(opts.login)) {
      console.warn(`addReviewer: ${opts.login} already a reviewer`);
      return;
    }

    // Estimate local state
    this.context.commit("setReviewer", opts);
    this.context.commit("calculateReviewStatus");

    // Add the reviewer
    const action: AddReviewerAction = {
      type: "add_reviewer",
      username: opts.login
    };

    return addReviewHistory(this.review.metadata, this.username, [action]);
  }

  @Action({ rawError: true })
  public removeReviewer(opts: { login: string }) {
    if (!this.review.state.reviewers.includes(opts.login)) {
      console.warn(`removeReviewer: ${opts.login} is not a reviewer`);
      return;
    }

    // Estimate local state
    this.context.commit("setReviewer", {
      login: opts.login,
      approved: undefined
    });
    this.context.commit("calculateReviewStatus");

    // Send the ReviewHistory
    const action: RemoveReviewerAction = {
      type: "remove_reviewer",
      username: opts.login
    };

    return addReviewHistory(this.review.metadata, this.username, [action]);
  }

  @Action({ rawError: true })
  public addAssignee(opts: { login: string }) {
    if (this.review.state.assignees.includes(opts.login)) {
      console.warn(`addAssignee: ${opts.login} already an assignee`);
      return;
    }

    // Estimate local state
    this.context.commit("setAssignee", { login: opts.login, assigned: true });

    // Add the Assignee
    const action: AddAssigneeAction = {
      type: "add_assignee",
      username: opts.login
    };

    return addReviewHistory(this.review.metadata, this.username, [action]);
  }

  @Action({ rawError: true })
  public removeAssignee(opts: { login: string }) {
    if (!this.review.state.assignees.includes(opts.login)) {
      console.warn(`removeAssignee: ${opts.login} is not an assignee`);
      return;
    }

    // Estimate local state
    this.context.commit("setAssignee", {
      login: opts.login,
      assigned: false
    });

    // Send the ReviewHistory
    const action: RemoveAssigneeAction = {
      type: "remove_assignee",
      username: opts.login
    };

    return addReviewHistory(this.review.metadata, this.username, [action]);
  }

  @Action({ rawError: true })
  public addCc(opts: { login: string }) {
    if (this.review.state.ccs.includes(opts.login)) {
      console.warn(`addCc: ${opts.login} already cc-ed`);
      return;
    }

    // Estimate local state
    this.context.commit("setCc", { login: opts.login, cced: true });

    const action: AddCcAction = {
      type: "add_cc",
      username: opts.login
    };

    return addReviewHistory(this.review.metadata, this.username, [action]);
  }

  @Action({ rawError: true })
  public removeCc(opts: { login: string }) {
    if (!this.review.state.ccs.includes(opts.login)) {
      console.warn(`removeCc: ${opts.login} is not cc-ed`);
      return;
    }

    // Estimate local state
    this.context.commit("setCc", { login: opts.login, cced: false });

    const action: RemoveCcAction = {
      type: "remove_cc",
      username: opts.login
    };

    return addReviewHistory(this.review.metadata, this.username, [action]);
  }

  @Mutation
  public setReviewer(opts: { login: string; approved?: boolean }) {
    const numReviewersBefore = this.review.state.reviewers.length;
    const reviewerBefore = this.review.state.reviewers.includes(opts.login);
    const reviewerAfter = opts.approved !== undefined;

    if (reviewerAfter) {
      setAdd(this.review.state.reviewers, opts.login);
    } else {
      setRemove(this.review.state.reviewers, opts.login);
    }

    if (opts.approved === true) {
      setAdd(this.review.state.approvers, opts.login);
    } else {
      setRemove(this.review.state.approvers, opts.login);
    }

    // If the reviewer is new, they are assigned
    // TODO: Can this be moved to the server?
    if (!reviewerBefore && reviewerAfter) {
      setAdd(this.review.state.assignees, opts.login);
    } else if (reviewerBefore && !reviewerAfter) {
      setRemove(this.review.state.assignees, opts.login);
    }
  }

  @Mutation
  setAssignee(opts: { login: string; assigned: boolean }) {
    if (opts.assigned) {
      setAdd(this.review.state.assignees, opts.login);
    } else {
      setRemove(this.review.state.assignees, opts.login);

      // If nobody is assigned, assign the author
      if (this.review.state.assignees.length === 0) {
        setAdd(this.review.state.assignees, this.review.metadata.author);
      }
    }
  }

  @Mutation
  setCc(opts: { login: string; cced: boolean }) {
    if (opts.cced) {
      setAdd(this.review.state.ccs, opts.login);
    } else {
      setRemove(this.review.state.ccs, opts.login);
    }
  }

  @Mutation
  setViewWindow(opts: { viewSinceTime: number; viewUntilTime: number }) {
    this.viewState.viewSinceTime = opts.viewSinceTime;
    this.viewState.viewUntilTime = opts.viewUntilTime;
  }

  @Mutation
  setSortOrder(opts: { sortOrder: string }) {
    this.viewState.sortOrder = opts.sortOrder;
  }

  @Mutation
  public setBaseAndHead(opts: { base: string; head: string }) {
    console.log(`review#setBaseAndHead(${opts.base}, ${opts.head})`);
    this.viewState.base = opts.base;
    this.viewState.head = opts.head;

    const baseIndex = this.viewState.commits.findIndex(
      c => c.sha.substring(0, 7) === this.viewState.base.substring(0, 7)
    );
    const headIndex = this.viewState.commits.findIndex(
      c => c.sha.substring(0, 7) === this.viewState.head.substring(0, 7)
    );
    this.viewState.visibleCommits = this.viewState.commits.slice(
      baseIndex,
      headIndex + 1
    );
  }

  @Mutation
  public setCommitStatus(commitStatus: CommitStatus) {
    this.commitStatus = commitStatus;
  }

  @Action({ rawError: true })
  public async newThread(opts: {
    username: string;
    args: ThreadArgs;
    proposedThreadId?: string;
    batch?: WriteBatch;
  }): Promise<Thread> {
    const { username, args, proposedThreadId, batch } = opts;
    console.log(
      `newThread(${JSON.stringify({ username, args, proposedThreadId })})`
    );

    const prHead = this.review.metadata.head.sha;
    if (args.sha !== prHead) {
      console.log(
        `newThread: thread posted on outdated commit (head=${prHead})`
      );
    }

    const threadId = proposedThreadId || newRandomId();
    const thread: Thread = {
      id: threadId,
      username: username,
      resolved: false,
      draft: true,
      currentArgs: args,
      originalArgs: args,
      lastUnresolvedBy: {
        username: username
      },
      lastResolvedBy: null
    };

    // Estimate local state
    this.context.commit("calculateReviewStatus");

    // Push the thread to Firebase
    const ref = doc(ReviewModule.threadsRef(this.review.metadata), thread.id);
    if (batch) {
      batch.set(ref, thread);
    } else {
      await setDoc(ref, thread);
    }

    return thread;
  }

  @Action({ rawError: true })
  public async newComment(opts: {
    threadId: string;
    user: CommentUser;
    text: string;
    resolve: boolean | null;
    reviewSummary?: boolean;
    batch?: WriteBatch;
  }): Promise<Comment> {
    const { threadId, user, text, resolve, reviewSummary, batch } = opts;
    console.log(
      `newComment(${JSON.stringify({
        threadId,
        user,
        text,
        resolve,
        reviewSummary
      })})`
    );

    const comment: Comment = {
      id: newRandomId(),
      threadId: threadId,
      setThreadResolution: resolve,
      username: user.username,
      photoURL: user.photoURL,
      text: text,

      timestamp: new Date().getTime(),
      draft: true
    };

    // Firestore can't handle undefined values
    if (reviewSummary !== undefined) {
      comment.reviewSummary = reviewSummary;
    }

    // Estimate local state
    this.context.commit("calculateReviewStatus");

    // Push the comment to Firebase
    const ref = doc(ReviewModule.commentsRef(this.review.metadata), comment.id);
    if (batch) {
      batch.set(ref, comment);
    } else {
      await setDoc(ref, comment);
    }

    return comment;
  }

  @Action({ rawError: true })
  public async discardDraftComments(opts: { username: string }) {
    const batch = writeBatch(firestore());

    const draftThreads = this.threads
      .filter(t => t.draft)
      .filter(t => t.username === opts.username);

    for (const thread of draftThreads) {
      batch.delete(
        doc(ReviewModule.threadsRef(this.review.metadata), thread.id)
      );
    }

    const draftComments = this.comments
      .filter(c => c.draft)
      .filter(c => c.username === opts.username);

    for (const comment of draftComments) {
      batch.delete(
        doc(ReviewModule.commentsRef(this.review.metadata), comment.id)
      );
    }

    await batch.commit();
  }

  @Action({ rawError: true })
  public async deleteDraftComment(opts: { id: string }) {
    const comment = this.comments.find(c => c.id === opts.id);
    if (!comment) {
      console.warn(`deleteDraftComment: no such comment ${opts.id}`);
      return;
    }
    const thread = this.threads.find(t => t.id === comment.threadId);
    if (!thread) {
      console.warn(`deleteDraftComment: no such thread ${comment.threadId}`);
      return;
    }

    const threadComments = this.comments.filter(c => c.threadId === thread.id);

    const batch = writeBatch(firestore());
    batch.delete(
      doc(ReviewModule.commentsRef(this.review.metadata), comment.id)
    );

    if (thread.draft && threadComments.length <= 1) {
      console.log(`deleteDraftComment: deleting draft thread ${thread.id}`);
      batch.delete(
        doc(ReviewModule.threadsRef(this.review.metadata), thread.id)
      );
    }

    await batch.commit();
  }

  @Action({ rawError: true })
  public async editDraftComment(opts: {
    id: string;
    text: string;
    setThreadResolution: boolean | null;
  }) {
    const ref = doc(ReviewModule.commentsRef(this.review.metadata), opts.id);
    const update: Partial<Comment> = {
      text: opts.text,
      setThreadResolution: opts.setThreadResolution
    };
    await updateDoc(ref, update);
  }

  @Action({ rawError: true })
  public async markFileReviewed(opts: {
    filename: string;
    login: string;
    reviewed: boolean;
  }) {
    console.log(`markFileReviewed: ${JSON.stringify(opts)}`);
    const ref = doc(
      collection(firestore(), reviewUserDatasPath(this.review.metadata)),
      opts.login
    );

    const data: any = {
      filesReviewed: {}
    };
    data.filesReviewed[opts.filename] = opts.reviewed
      ? this.review.metadata.head.sha
      : deleteField();

    await setDoc(ref, data, { merge: true });
  }

  @Action({ rawError: true })
  public async sendReview(opts: { login: string; approved: boolean }) {
    const batch = writeBatch(firestore());
    const nowTime = new Date().getTime();

    // Is the user a reviewer after this review? If one of:
    // - They were a reviewer before
    // - They are newly approving
    // - They are newly adding "needs resolution" comments
    const alreadyReviewed = this.review.state.reviewers.includes(opts.login);
    const newlyApproved = opts.approved;
    const addingNewUnresolved = this.countResolutions.newUnresolved > 0;

    const willBeReviewer =
      alreadyReviewed || newlyApproved || addingNewUnresolved;

    // Is the user the author of the PR?
    const author = this.review.metadata.author;
    const userIsAuthor = author === opts.login;

    // Is the user adding explicit review state?
    const newApproval = willBeReviewer ? opts.approved : null;

    // Has the user already approved this PR?
    const previousApproval = alreadyReviewed
      ? this.review.state.approvers.includes(opts.login)
      : null;

    // Set the reviewer's approval state (locally)
    const approvalChanged = newApproval !== previousApproval;
    if (!userIsAuthor && approvalChanged) {
      this.context.commit("setReviewer", opts);
    }

    // Create a review history doc and add actions to it
    const history: ReviewHistory = {
      username: opts.login,
      approval: newApproval,
      previousApproval: previousApproval,
      actions: [],
      timestamp: nowTime,
      metadata: {
        owner: this.review.metadata.owner,
        repo: this.review.metadata.repo
      }
    };

    // Find all threads that are drafts which we started.
    const draftThreads = this.threads
      .filter(t => t.draft)
      .filter(t => t.username === opts.login);

    for (const thread of draftThreads) {
      history.actions.push({
        type: "add_thread",
        threadId: thread.id
      });

      batch.update(
        doc(ReviewModule.threadsRef(this.review.metadata), thread.id),
        {
          draft: false
        }
      );
    }

    // Find all comments that are drafts which we wrote.
    const draftComments = this.comments
      .filter(c => c.draft)
      .filter(t => t.username === opts.login)
      .sort((a, b) => a.timestamp - b.timestamp);

    for (let i = 0; i < draftComments.length; i++) {
      const comment = draftComments[i];

      // Update the timestamp to the current time, but add 1ms per
      // comment to preserve ordering among drafts
      const timestamp = nowTime + i;

      // Add the comment to the database
      history.actions.push({
        type: "add_comment",
        commentId: comment.id,
        threadId: comment.threadId
      });
      batch.update(
        doc(ReviewModule.commentsRef(this.review.metadata), comment.id),
        {
          draft: false,
          timestamp
        }
      );

      // If the comment changes any thread resolution, handle that
      if (comment.setThreadResolution !== null) {
        history.actions.push({
          type: "resolve_thread",
          threadId: comment.threadId,
          resolved: comment.setThreadResolution
        });

        // TODO(stop): Can/should we do this from the server?
        const threadUpdate: Partial<Thread> = {
          resolved: comment.setThreadResolution
        };

        if (comment.setThreadResolution === true) {
          threadUpdate.lastResolvedBy = {
            username: comment.username
          };
        }

        if (comment.setThreadResolution === false) {
          // A thread can only be lastUnresolvedBy the PR author
          // if it was started by the PR author.
          const thread = this.threadById(comment.threadId)!;
          const commentByAuthor = comment.username === author;
          const threadByAuthor = thread.username === author;
          const shouldUpdateUnresolved = commentByAuthor
            ? threadByAuthor
            : false;

          if (shouldUpdateUnresolved) {
            threadUpdate.lastUnresolvedBy = {
              username: comment.username
            };
          }
        }

        batch.update(
          doc(ReviewModule.threadsRef(this.review.metadata), comment.threadId),
          threadUpdate
        );
      }
    }

    // Add the history to the batch
    const historyDoc = doc(
      collection(ReviewModule.reviewRef(this.review.metadata), "history")
    );
    batch.set(historyDoc, history);
    console.log(`sendReview: history= ${JSON.stringify(history)}`);

    // Estimate local state
    this.context.commit("calculateReviewStatus");

    // Commit the batch to Firestore
    await batch.commit();
  }

  @Action({ rawError: true })
  public async handleAddCommentEvent(opts: {
    e: events.AddCommentEvent;
    user: CommentUser;
  }) {
    console.log(`review#handleAddCommentEvent(${JSON.stringify(opts)})`);

    const e = opts.e;
    const batch = writeBatch(firestore());

    let threadId: string | undefined = undefined;
    if (e.type === "new_thread_add_comment") {
      const threadArgs: ThreadArgs = {
        file: e.file,
        sha: e.sha,
        side: e.side,
        line: e.line,
        lineContent: e.lineContent
      };

      const newThread = await this.newThread({
        username: opts.user.username,
        args: threadArgs,
        proposedThreadId: e.proposedThreadId,
        batch
      });

      threadId = newThread.id;
    } else {
      threadId = e.threadId;
    }

    // Add comment
    const resolve = e.resolve === undefined ? null : e.resolve;
    const reviewSummary =
      e.type === "new_thread_add_comment" ? e.reviewSummary : undefined;

    await this.newComment({
      threadId: threadId!,
      user: opts.user,
      text: e.content,
      resolve,
      reviewSummary,
      batch
    });

    // Estimate local state
    this.context.commit("calculateReviewStatus");

    // Commit the write batch
    await batch.commit();
  }
}
