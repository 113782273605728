
import { Component, Vue, Prop } from "vue-property-decorator";

import { signInWithCustomToken } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

import { config } from "../../plugins/config";
import { auth, functions } from "../../plugins/firebase";
import * as cookies from "../../plugins/cookies";

import { getModule } from "vuex-module-decorators";

import { User, createUser } from "../../model/auth";
import AuthModule from "../../store/modules/auth";
import UIModule from "../../store/modules/ui";
import { FLAGS, getBooleanFlag } from "@/plugins/flags";

@Component({
  components: {}
})
export default class SignIn extends Vue {
  private authModule = getModule(AuthModule, this.$store);
  private uiModule = getModule(UIModule, this.$store);

  public emailChecked = false;
  public email = "";

  public enableRequireInvite = getBooleanFlag(FLAGS.ENABLE_REQUIRE_INVITE);

  async mounted() {
    if (this.hasCustomToken) {
      this.signInCustom(this.$route.query.custom_token as string);
    }
  }

  get title() {
    return "Sign In";
  }

  get hideEmailForm() {
    return this.emailChecked || !this.enableRequireInvite;
  }

  public async checkEmail() {
    this.uiModule.beginLoading();

    const email = this.email.trim().toLowerCase();

    const fn = httpsCallable(functions(), "api/checkSigninAllowed");
    const res = await fn({ email });
    const found = (res.data as any).found;

    if (found === true) {
      this.emailChecked = true;
    } else {
      this.uiModule.addDisappearingError(
        "Sorry, that email address doesn't match any existing user or invite."
      );
    }

    this.uiModule.endLoading();
  }

  private async signInCustom(customToken: string) {
    this.uiModule.beginLoading();
    try {
      const result = await signInWithCustomToken(auth(), customToken);
      const tokenRes = await httpsCallable(functions(), "api/getGithubToken")();

      const access_token = (tokenRes as any).data.access_token;
      const access_token_expires = (tokenRes as any).data.access_token_expires;

      if (result.user) {
        const user: User = createUser(
          result.user,
          access_token,
          access_token_expires
        );
        this.authModule.setUser(user);

        const pendingPath = this.$cookies.get(cookies.SIGNIN_PATH);
        if (pendingPath) {
          this.$cookies.remove(cookies.SIGNIN_PATH);
          this.$router.push(pendingPath);
        } else {
          this.$router.push("/inbox");
        }
      } else {
        this.authModule.setUser(null);
      }
    } catch (e) {
      console.warn(`Sign in failure: ${e}`);
      this.uiModule.addDisappearingError("Sign in failed.");
      this.authModule.setUser(null);
    }
    this.uiModule.endLoading();
  }

  get hasCustomToken() {
    return !!this.$route.query.custom_token;
  }

  get githubUrl() {
    return `https://github.com/login/oauth/authorize?client_id=${config.github.client_id}&redirect_uri=${config.github.redirect}`;
  }
}
