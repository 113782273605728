
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import SimpleLabeledSelect from "@/components/elements/SimpleLabeledSelect.vue";
import RepoSearchModal from "@/components/elements/RepoSearchModal.vue";
import ChangeEntry from "@/components/elements/ChangeEntry.vue";
import RichTextArea from "@/components/elements/RichTextArea.vue";
import Avatar from "@/components/elements/Avatar.vue";
import UserSearchModal from "@/components/elements/UserSearchModal.vue";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot
} from "firebase/firestore";

import AuthModule from "../../store/modules/auth";
import UIModule from "../../store/modules/ui";
import { config } from "../../plugins/config";
import { firestore } from "../../plugins/firebase";
import { LocalSettings } from "../../plugins/localSettings";
import { PullRequestChange, renderDiffToChange } from "../../plugins/diff";

import { Github, RecentBranchNode } from "../../../../shared/github";
import {
  repoPath,
  reviewHistoriesPath,
  reviewPath
} from "../../../../shared/database";
import {
  AddCcAction,
  AddReviewerAction,
  DiffMode,
  RepoBranchPair,
  ReviewHistory
} from "../../../../shared/types";
import { Latch } from "../../../../shared/asyncUtils";
import {
  formatTimestampShort,
  setAdd,
  setRemove,
  splitFilter
} from "../../../../shared/typeUtils";

type BranchCommit = {
  sha: string;
  html_url: string;
  author: {
    login: string;
  };
  commit: {
    message: string;
    author: {
      date: string;
    };
  };
};

@Component({
  components: {
    SimpleLabeledSelect,
    RepoSearchModal,
    ChangeEntry,
    RichTextArea,
    Avatar,
    UserSearchModal
  }
})
export default class Create extends Vue {
  private authModule = getModule(AuthModule, this.$store);
  private uiModule = getModule(UIModule, this.$store);

  public searchingForRepo = false;
  public repo: { owner: string; name: string } | null = null;

  public defaultBranch = "";
  public branches: RecentBranchNode[] = [];

  public baseBranch = "";
  public headBranch = "";
  public diff: PullRequestChange[] = [];

  public commits: BranchCommit[] = [];

  public prTitle = "";
  public description = "";

  public existingPrNumber = -1;
  public existsOnCodeApprove = false;

  public showReviewersDialog = false;
  public reviewers: string[] = [];

  public showCcsDialog = false;
  public ccs: string[] = [];

  public mode: DiffMode = LocalSettings.getOrDefault(
    LocalSettings.KEY_DIFF_MODE,
    "split"
  );

  private github: Github = new Github(
    AuthModule.getDelegate(this.authModule),
    config.github
  );

  async mounted() {
    // Query Params:
    // owner - GitHub owner login
    // repo - the repo name
    // base - the base branch name
    // head - the head branch name
    // title - the pr title
    // body - the pr body
    const { owner, repo, base, head, title, body } = this.$route.query;

    if (typeof owner === "string" && typeof repo === "string") {
      await this.onRepoSelected(owner, repo);
    }

    if (
      this.repo != null &&
      typeof base === "string" &&
      typeof head === "string"
    ) {
      this.setBaseAndHead(base, head);
    }

    if (typeof title === "string") {
      this.prTitle = title;
    }

    if (typeof body === "string") {
      this.description = body;
    }
  }

  public onReviewerSelected(event: { login: string }) {
    this.showReviewersDialog = false;
    setAdd(this.reviewers, event.login);
  }

  public removeReviewer(login: string) {
    setRemove(this.reviewers, login);
  }

  public onCcSelected(event: { login: string }) {
    this.showCcsDialog = false;
    setAdd(this.ccs, event.login);
  }

  public removeCc(login: string) {
    setRemove(this.ccs, login);
  }

  private setBaseAndHead(base: string, head: string) {
    const foundBase = this.branches.find(b => b.name === base);
    if (!foundBase) {
      this.uiModule.addDisappearingError(`Couldn't find branch "${base}"`);
      return;
    }

    const foundHead = this.branches.find(b => b.name === head);
    if (!foundHead) {
      this.uiModule.addDisappearingError(`Couldn't find branch "${head}"`);
      return;
    }

    this.baseBranch = base;
    this.headBranch = head;
  }

  get title() {
    return "Create";
  }

  private async checkRepoAccess(owner: string, name: string): Promise<boolean> {
    // Check if it's on CA and the user can access it
    const repoRef = doc(firestore(), repoPath({ owner, repo: name }));
    try {
      const repo = await getDoc(repoRef);
      if (!repo.exists()) {
        this.uiModule.addDisappearingError(
          `Sorry, "${owner}/${name}" is not yet on CodeApprove.`
        );
        return false;
      }
    } catch (e) {
      this.uiModule.addDisappearingError(
        `Sorry, "${owner}/${name}" is not on CodeApprove or you do not have access.`
      );
      return false;
    }

    // Check that the user can write to the repo
    let userCanWrite = false;
    try {
      const permission = await this.github.getPermissionForRepo(
        owner,
        name,
        this.authModule.assertUser.username
      );
      userCanWrite = permission === "write" || permission === "admin";
    } catch (e) {
      // No-op, if the repo does not have CA this can fail
    }

    if (!userCanWrite) {
      this.uiModule.addDisappearingError(
        `Sorry, you don't have write access to "${owner}/${name}" so you can't open a Pull Request.`
      );
      return false;
    }

    return true;
  }

  public async onRepoSelected(owner: string, name: string) {
    this.uiModule.beginLoading();

    this.searchingForRepo = false;

    const canAccessRepo = await this.checkRepoAccess(owner, name);
    if (!canAccessRepo) {
      this.uiModule.endLoading();
      return;
    }

    // Load branches
    this.defaultBranch = await this.github.getDefaultBranch(owner, name);
    this.branches = await this.github.listRecentBranches(owner, name);
    this.baseBranch = this.baseOptions[0];
    this.headBranch = this.headOptions[0];

    if (this.branches.length >= 500) {
      this.uiModule.addDisappearingError(
        "This repository has more than 500 branches, some branches may be missing"
      );
    }

    // Do this last to trigger the UI
    this.repo = { owner, name };

    this.uiModule.endLoading();
  }

  @Watch("baseAndHead")
  public async onBranchesSelected() {
    console.log(`onBranchesSelected(${JSON.stringify(this.baseAndHead)})`);
    if (!this.hasBranches) {
      this.diff = [];
      this.commits = [];
      return;
    }

    const { owner, name } = this.repo!;

    this.uiModule.beginLoading();

    // Reset diff
    this.diff = [];

    // Check if the PR already exists on GitHub
    const existing = await this.github.findPullRequest(
      owner,
      name,
      this.baseBranch,
      `${owner}:${this.headBranch}`
    );

    // If it exists, check on CodeApprove
    if (existing) {
      console.log(`PR already exists: ${existing.html_url}`);
      this.existingPrNumber = existing.number;

      try {
        const snap = await getDoc(
          doc(
            firestore(),
            reviewPath({ owner, repo: name, number: existing.number })
          )
        );

        if (snap.exists()) {
          this.existsOnCodeApprove = true;
        }
      } catch (e) {
        // ...
      }
    } else {
      this.existingPrNumber = -1;
      this.existsOnCodeApprove = false;
    }

    if (!this.exists) {
      const baseLabel = `${owner}:${this.baseBranch}`;
      const headLabel = `${owner}:${this.headBranch}`;

      const diffs = await this.github.getDiff(
        owner,
        name,
        baseLabel,
        headLabel
      );

      this.diff = diffs.map(diff => renderDiffToChange(diff, this.mode));

      const compareRes = await this.github.compareCommits(
        owner,
        name,
        baseLabel,
        headLabel
      );

      this.commits = compareRes.commits as BranchCommit[];
    }

    this.uiModule.endLoading();
  }

  public reset() {
    this.repo = null;

    this.defaultBranch = "";
    this.branches = [];

    this.baseBranch = "";
    this.headBranch = "";
    this.diff = [];

    this.commits = [];

    this.prTitle = "";
    this.description = "";

    this.existingPrNumber = -1;
    this.existsOnCodeApprove = false;

    this.showReviewersDialog = false;
    this.reviewers = [];

    this.showCcsDialog = false;
    this.ccs = [];
  }

  public async createPr() {
    if (!this.canCreate) {
      return;
    }

    const { owner, name } = this.repo!;

    this.uiModule.beginLoading();

    try {
      // Create the PR
      const res = await this.github.createPullRequest(
        owner,
        name,
        this.baseBranch,
        this.headBranch,
        this.prTitle,
        this.description
      );

      // Add the review to codeApprove
      const number = res.data.number;
      await this.addReviewToCodeApprove(owner, name, number);

      // Navigate to the review
      const route = `/pr/${owner}/${name}/${number}`;
      this.$router.push(route);
    } catch (e) {
      console.warn(e);
      this.uiModule.addDisappearingError(
        "There was an error creating your review."
      );
    }

    this.uiModule.endLoading();
  }

  async addReviewToCodeApprove(owner: string, name: string, number: number) {
    // Label it
    await this.github.labelPullRequest(owner, name, number, "codeapprove");

    // Wait for it to show up in firestore
    const latch = new Latch();
    latch.increment();

    const unsub = onSnapshot(
      doc(firestore(), reviewPath({ owner, repo: name, number })),
      snap => {
        if (snap.exists()) {
          latch.decrement();
          unsub();
        }
      }
    );

    await latch.wait();

    // Build a review history and add the reviewers and cc's
    if (this.reviewers.length > 0 || this.ccs.length > 0) {
      const reviewerActions: AddReviewerAction[] = this.reviewers.map(r => ({
        type: "add_reviewer",
        username: r
      }));

      const ccActions: AddCcAction[] = this.ccs.map(c => ({
        type: "add_cc",
        username: c
      }));

      const history: ReviewHistory = {
        username: this.authModule.assertUser.username,
        approval: null,
        previousApproval: null,
        actions: [...reviewerActions, ...ccActions],
        timestamp: new Date().getTime(),
        metadata: {
          owner,
          repo: name
        }
      };

      const coll = collection(
        firestore(),
        reviewHistoriesPath({ owner, repo: name, number })
      );
      await addDoc(coll, history);
    }
  }

  public async onAddToCodeApproveClicked() {
    const { owner, name } = this.repo!;
    const number = this.existingPrNumber;

    this.uiModule.beginLoading();

    try {
      // Add the review to CodeApprove
      await this.addReviewToCodeApprove(owner, name, number);

      // Navigate to the review
      const route = `/pr/${owner}/${name}/${number}`;
      this.$router.push(route);
    } catch (e) {
      console.warn(e);
      this.uiModule.addDisappearingError(
        "There was an error creating your review."
      );
    }

    this.uiModule.endLoading();
  }

  public changeEntryKey(change: PullRequestChange) {
    return `${change.metadata.from}@${this.baseBranch}-${change.metadata.to}@${this.headBranch}`;
  }

  public commitTimestamp(commit: BranchCommit) {
    return formatTimestampShort(new Date(commit.commit.author.date).getTime());
  }

  get headOptions() {
    // Prioritize my branches which have been pushed in the last 24 hours
    // Note: branches are already sorted by date, and splitFilter preserves that
    const username = this.authModule.assertUser.username;
    const oneDayMs = 24 * 60 * 60 * 1000;
    const [myRecentBranches, otherBranches] = splitFilter(this.branches, b => {
      const isMine =
        !!b.target.author &&
        !!b.target.author.user &&
        b.target.author.user.login === username;
      const isRecent =
        Date.now() - new Date(b.target.committedDate || 0).getTime() <=
        oneDayMs;

      return isMine && isRecent;
    });

    // Filter out the default
    return [...myRecentBranches, ...otherBranches]
      .filter(b => b.name !== this.defaultBranch)
      .map(b => b.name);
  }

  get baseOptions() {
    return [this.defaultBranch, ...this.headOptions];
  }

  get baseAndHead() {
    return {
      base: this.baseBranch,
      head: this.headBranch
    };
  }

  get exists() {
    return this.existingPrNumber >= 0;
  }

  get existingLink() {
    const { owner, name } = this.repo!;
    if (this.existsOnCodeApprove) {
      return `/pr/${owner}/${name}/${this.existingPrNumber}`;
    } else {
      return `https://github.com/${owner}/${name}/pull/${this.existingPrNumber}`;
    }
  }

  get hasBranches() {
    return this.baseBranch.length > 0 && this.headBranch.length > 0;
  }

  get canCreate() {
    const hasRepo = this.repo != null;
    const hasTitle = this.prTitle.length > 0;
    return hasRepo && hasTitle && this.hasBranches && !this.exists;
  }

  get repoBranchPair(): RepoBranchPair | null {
    if (this.repo === null || !this.hasBranches) {
      return null;
    }

    return {
      owner: this.repo.owner,
      repo: this.repo.name,
      base: this.baseBranch,
      head: this.headBranch
    };
  }
}
