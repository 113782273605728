
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class UserReviewIcon extends Vue {
  @Prop() approved!: boolean;
  @Prop() canAssign!: boolean;
  @Prop() canRemove!: boolean;

  public hovered = false;

  public removeClicked() {
    this.$emit("remove-clicked");
  }

  public assignClicked() {
    this.$emit("assign-clicked");
  }
}
