
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import Avatar from "@/components/elements/Avatar.vue";
import SearchModal from "@/components/elements/SearchModal.vue";

import { Github } from "../../../../shared/github";
import { UserSearchItem } from "../../../../shared/types";
import { config } from "../../plugins/config";
import AuthModule from "../../store/modules/auth";

@Component({
  components: {
    Avatar,
    SearchModal
  }
})
export default class UserSearchModal extends Vue {
  @Prop() owner!: string;
  @Prop() repo!: string;
  @Prop({
    // See:
    // https://stackoverflow.com/a/64366552/324977
    default() {
      return [];
    }
  })
  hints!: string[];

  public query: string = "";
  public loading = false;

  private items: UserSearchItem[] = [];
  private hintItems: UserSearchItem[] = [];

  private github!: Github;
  private authModule = getModule(AuthModule, this.$store);
  private searchFn: Function = () => {};

  async mounted() {
    this.github = new Github(
      AuthModule.getDelegate(this.authModule),
      config.github
    );

    // Render hints as UserSearchItems
    this.hintItems = this.hints.map(u => {
      return {
        id: u,
        login: u,
        name: null,
        collaborator: true
      };
    });
    this.items = this.hintItems;

    this.searchFn = this.debounce(async () => {
      if (!this.query || this.query.length === 0) {
        this.loading = false;
        return;
      }

      this.loading = true;

      const res = await this.github.searchUsers(
        this.owner,
        this.repo,
        this.query,
        true
      );
      const searchItems = res.slice(0, 10);
      this.loading = false;

      this.items = this.mergeItems(this.query, searchItems);
    }, 300);
  }

  @Watch("query")
  async onQuery() {
    if (this.query === "") {
      this.items = this.hintItems;
    }

    this.searchFn();
  }

  get sortedItems() {
    if (!this.items) {
      return [];
    }

    if (!this.query) {
      return this.items;
    }

    // Prefer:
    // - Login prefix matches
    // - Name prefix matches
    return this.items
      .filter(i => !!i.login)
      .map((item, index) => {
        let score = 0;

        if (item.login.startsWith(this.query)) {
          score = 2;
        } else if (item.name && item.name.startsWith(this.query)) {
          score = 1;
        }

        return { item, score, index };
      })
      .sort((a, b) => {
        // Higher score items always come first
        if (a.score !== b.score) {
          return b.score - a.score;
        }

        // Otherwise, use the original sort
        return a.index - b.index;
      })
      .map(v => v.item);
  }

  public onSelected(e: { item: UserSearchItem }) {
    this.$emit("selected", { login: e.item.login });
  }

  private debounce(func: Function, waitFor: number): Function {
    let timeout: any = undefined;

    const debounced = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(), waitFor);
    };

    return debounced;
  }

  private mergeItems(query: string, items: UserSearchItem[]) {
    const itemsFiltered = items.filter(i => i.login);

    // Filter hint items by the query, drop duplicates
    const hintsFiltered = this.hintItems
      .filter(i => i.login.toLowerCase().includes(query.toLowerCase()))
      .filter(i => !items.some(x => x.login === i.login));

    return [...hintsFiltered, ...itemsFiltered];
  }
}
