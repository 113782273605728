import { render, staticRenderFns } from "./PullRequestCheck.vue?vue&type=template&id=788d0fa0&scoped=true"
import script from "./PullRequestCheck.vue?vue&type=script&lang=ts"
export * from "./PullRequestCheck.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "788d0fa0",
  null
  
)

export default component.exports