
import { Component, Vue, Prop } from "vue-property-decorator";
import LabeledSelect from "@/components/elements/LabeledSelect.vue";

@Component({
  components: {
    LabeledSelect
  }
})
export default class SimpleLabeledSelect extends Vue {
  @Prop({ default: null }) value!: string | null;

  @Prop() label!: string;
  @Prop() keys!: string[];
  @Prop() values!: string[];
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "right" }) align!: string;
}
