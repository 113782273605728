
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ToggleSwitch extends Vue {
  // Use with v-model
  @Prop() value!: boolean;

  @Prop() label!: string;

  @Prop({ default: false }) disabled!: boolean;

  async mounted() {}

  public onChange(e: Event) {
    const checked = (e.target as HTMLInputElement).checked;
    this.$emit("input", checked);
  }
}
