
import { Component, Vue, Prop } from "vue-property-decorator";

interface Plan {
  name: string;
  description: string;
  price: number;
  unit: string;
  subUnit: string;
  cta: string;
  ctaPath: string;
  features: { text: string; status: "green" | "yellow" | "red" }[];
}

@Component({
  components: {}
})
export default class Pricing extends Vue {
  PLAN_FREE: Plan = {
    name: "Free",
    description:
      "Try CodeApprove on one public repository in your org. Free forever, no credit card required.",
    price: 0,
    unit: "repo",
    subUnit: "(free)",
    cta: "Sign Up",
    ctaPath: "/interest",
    features: [
      { text: "Full CodeApprove experience", status: "green" },
      { text: "Unlimited collaborators", status: "green" },
      { text: "1 public repo", status: "yellow" },
      { text: "0 private repos", status: "red" }
    ]
  };

  PLAN_ORG: Plan = {
    name: "Open Source",
    description:
      "Use CodeApprove on all your public repositories in a single org. Perfect for large open-source projects.",
    price: 19,
    unit: "org",
    subUnit: "(per month)",
    cta: "Sign Up",
    ctaPath: "/interest",
    features: [
      { text: "Full CodeApprove experience", status: "green" },
      { text: "Unlimited collaborators", status: "green" },
      { text: "Unlimited public repos", status: "green" },
      { text: "0 private repos", status: "red" }
    ]
  };

  PLAN_TEAM: Plan = {
    name: "Team",
    description:
      "Use CodeApprove on unlimited public and private repositories. Best for teams with private repos.",
    price: 9,
    unit: "user",
    subUnit: "(per month)",
    cta: "Sign Up",
    ctaPath: "/interest",
    features: [
      { text: "Full CodeApprove experience", status: "green" },
      { text: "Unlimited collaborators", status: "green" },
      { text: "Unlimited public repos", status: "green" },
      { text: "Unlimited private repos", status: "green" }
    ]
  };

  plans: Plan[] = [this.PLAN_FREE, this.PLAN_ORG, this.PLAN_TEAM];

  async mounted() {}

  get title() {
    return "Pricing";
  }
}
