import {
  Review,
  ReviewState,
  ReviewStatus,
  ReviewMetadata,
  Thread,
  Comment,
  ReviewAction,
  ReviewHistory,
  ReviewPointer,
  UserSearchItem,
  User,
  ReviewIdentifier,
  AddCommentAction,
  AddThreadAction,
  AddReviewerAction,
  RemoveReviewerAction,
  RemoveAssigneeAction,
  AddAssigneeAction,
  AddCcAction,
  RemoveCcAction,
} from "./types";
import * as config from "./config";

export function calculateReviewStatus(
  metadata: ReviewMetadata,
  state: ReviewState
): ReviewStatus {
  if (state.reviewers.length === 0) {
    return ReviewStatus.NEEDS_REVIEW;
  } else {
    // TODO(polish): Maybe block self-approval
    if (state.approvers.length > 0) {
      if (state.unresolved > 0) {
        return ReviewStatus.NEEDS_RESOLUTION;
      } else {
        return ReviewStatus.APPROVED;
      }
    } else {
      return ReviewStatus.NEEDS_APPROVAL;
    }
  }
}

export function reviewMetadatasEqual(a: ReviewMetadata, b: ReviewMetadata) {
  return a.owner === b.owner && a.repo === b.repo && a.number === b.number;
}

export function calculateAssignees(
  review: Review,
  threads: Thread[],
  comments: Comment[]
): string[] {
  const assignees: string[] = [];
  if (isAuthorsTurn(review, threads, comments)) {
    setAdd(assignees, review.metadata.author);
  }

  const reviewerAssignees = whichReviewersTurn(review, threads, comments);
  for (const r of reviewerAssignees) {
    setAdd(assignees, r);
  }

  // If it's nobody's turn then it's the author's tuen
  if (assignees.length === 0) {
    setAdd(assignees, review.metadata.author);
  }

  return assignees.sort();
}

/**
 * It is the author's turn if any of these is true and the PR is open:
 *   (1) The review is 100% approved
 *   (2) There are no reviewers
 *   (3) There are unresolved threads where the author is not the last reply
 */
export function isAuthorsTurn(
  review: Review,
  threads: Thread[],
  comments: Comment[]
): boolean {
  if (review.state.closed) {
    return false;
  }

  // (1)
  if (isApproved(review.state)) {
    return true;
  }

  // (2)
  if (review.state.reviewers.length === 0) {
    return true;
  }

  // (3)
  // TODO(polish): We should filter out the drafts before we get here
  const unresolved = threads.filter((t) => !t.draft && !t.resolved);
  for (const t of unresolved) {
    const tComments = comments
      .filter((c) => !c.draft && c.threadId === t.id)
      .sort((a, b) => b.timestamp - a.timestamp);
    const lastComment = tComments[0];

    if (!lastComment) {
      console.warn(`Thread ${t.id} has no comments!`);
    }

    // TODO(polish): We shouldn't need to check if lastComment exists here
    // but we had at least one instance of a no-comment thread getting through.
    if (lastComment && lastComment.username !== review.metadata.author) {
      console.log(
        `PR ${review.metadata.number}: it is the author's turn because the last comment on ${t.id} was by ${lastComment.username}`
      );
      return true;
    }
  }

  return false;
}

/**
 * It is the reviewers turn if any of the below is true and the PR is open:
 *  (1) There are replies to unresolved comments that they started and the last reply is not by them
 *  (2) All comments have been resolved but they have not approved
 *  (3) If there have been pushes since their last comment (TODO)
 */
export function whichReviewersTurn(
  review: Review,
  threads: Thread[],
  comments: Comment[]
): string[] {
  if (review.state.closed) {
    return [];
  }

  if (review.state.reviewers.length === 0) {
    return [];
  }

  const whoseTurn: Set<string> = new Set();

  // TODO(polish): We should filter out the drafts before we get here
  const unresolved = threads.filter((t) => !t.draft && !t.resolved);
  for (const t of unresolved) {
    const tComments = comments
      .filter((c) => !c.draft && c.threadId === t.id)
      .sort((a, b) => b.timestamp - a.timestamp);
    const lastComment = tComments[0];

    // TODO(polish): We shouldn't need to check if lastComment exists here
    // but we had at least one instance of a no-comment thread getting through.
    if (!lastComment) {
      console.warn(`Thread ${t.id} has no comments`);
      continue;
    }

    if (lastComment.username !== t.username) {
      console.log(
        `PR ${review.metadata.number}: it is ${t.username}'s turn because the last comment on ${t.id} was by ${lastComment.username}`
      );
      whoseTurn.add(t.username);
    }
  }

  // Reviewers who have not approved
  const pendingReviewers = review.state.reviewers.filter(
    (r) => !review.state.approvers.includes(r)
  );

  // Find non-approving reviewers who have had all their comments resolved
  for (const r of pendingReviewers) {
    const rThreads = threads.filter((t) => t.username === r);
    const hasUnresolved = rThreads.some((t) => !t.resolved);
    if (!hasUnresolved) {
      console.log(
        `PR ${review.metadata.number}: it is ${r}'s turn because they have not approved but all their comments are resolved`
      );
      whoseTurn.add(r);
    }
  }

  return Array.from(whoseTurn);
}

export function statusClass(status: ReviewStatus) {
  switch (status) {
    case ReviewStatus.APPROVED:
      return {
        text: "text-green-400",
        border: "border-green-400",
      };
    case ReviewStatus.CLOSED_MERGED:
      return {
        text: "text-purple-400",
        border: "border-purple-400",
      };
    case ReviewStatus.CLOSED_UNMERGED:
      return {
        text: "text-red-400",
        border: "border-red-400",
      };
    case ReviewStatus.NEEDS_RESOLUTION:
    case ReviewStatus.NEEDS_APPROVAL:
    case ReviewStatus.NEEDS_REVIEW:
      return {
        text: "text-yellow-300",
        border: "border-yellow-300",
      };
  }
}

export function statusText(status: ReviewStatus): string {
  switch (status) {
    case ReviewStatus.APPROVED:
      return "Approved";
    case ReviewStatus.CLOSED_MERGED:
      return "Merged";
    case ReviewStatus.CLOSED_UNMERGED:
      return "Closed";
    case ReviewStatus.NEEDS_REVIEW:
      return "Needs Review";
    case ReviewStatus.NEEDS_RESOLUTION:
      return "Needs Resolution";
    case ReviewStatus.NEEDS_APPROVAL:
      return "Needs Approval";
  }
}

export function statusIconName(status: ReviewStatus): string {
  switch (status) {
    case ReviewStatus.APPROVED:
      return "check";
    case ReviewStatus.CLOSED_MERGED:
      return "code-branch";
    case ReviewStatus.CLOSED_UNMERGED:
      return "xmark";
    case ReviewStatus.NEEDS_RESOLUTION:
    case ReviewStatus.NEEDS_APPROVAL:
    case ReviewStatus.NEEDS_REVIEW:
      return "circle-pause";
  }
}

export function statusEmoji(status: ReviewStatus): string {
  switch (status) {
    case ReviewStatus.APPROVED:
      return "✔️";
    case ReviewStatus.CLOSED_MERGED:
      return "🚀";
    case ReviewStatus.CLOSED_UNMERGED:
      return "🗑️";
    case ReviewStatus.NEEDS_APPROVAL:
      return "⏳";
    case ReviewStatus.NEEDS_REVIEW:
      return "⏳";
    case ReviewStatus.NEEDS_RESOLUTION:
      return "❌";
  }
}

export function statusFavicon(status: ReviewStatus): string {
  switch (status) {
    case ReviewStatus.APPROVED:
      return "/favicon-approved.ico";
    case ReviewStatus.CLOSED_MERGED:
      return "/favicon.ico";
    case ReviewStatus.CLOSED_UNMERGED:
      return "/favicon-closed.ico";
    case ReviewStatus.NEEDS_REVIEW:
      return "/favicon-pending.ico";
    case ReviewStatus.NEEDS_RESOLUTION:
      return "/favicon-pending.ico";
    case ReviewStatus.NEEDS_APPROVAL:
      return "/favicon-pending.ico";
  }
}

function describeStatus(status: ReviewStatus): string {
  return `${statusEmoji(status)} ${statusText(status)}`;
}

export function isApproved(state: ReviewState): boolean {
  return state.status === ReviewStatus.APPROVED;
}

export function formatTimestamp(timestamp: number) {
  const locale = getLocale();

  // Ex: "Sep 20, 9:01AM"
  const dateFormat = new Intl.DateTimeFormat(locale, {
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  });

  return dateFormat.format(new Date(timestamp));
}

export function formatTimestampLong(timestamp: number): string {
  const d = new Date(timestamp);
  const now = new Date();
  const locale =
    typeof navigator !== "undefined" && navigator.language
      ? navigator.language
      : "en-US";

  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  });

  const timeFormat = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "numeric",
  });

  const onSameDay =
    d.getFullYear() === now.getFullYear() &&
    d.getMonth() === now.getMonth() &&
    d.getDate() === now.getDate();

  if (onSameDay) {
    return timeFormat.format(d);
  } else {
    return dateTimeFormat.format(d);
  }
}

export function getLocale() {
  return typeof navigator !== "undefined" && navigator.language
    ? navigator.language
    : "en-US";
}

// TODO(polish): DRY these two functions
export function formatTimestampShort(timestamp: number): string {
  const today = new Date();
  const date = new Date(timestamp);
  const locale = getLocale();

  // Ex: "Sep 20"
  const dateFormat = new Intl.DateTimeFormat(locale, {
    month: "short",
    day: "2-digit",
  });

  // Ex: "9:01 AM"
  const timeFormat = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "numeric",
  });

  const onSameDay =
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate();

  // If it's today, then we show a time instead
  if (onSameDay) {
    return timeFormat.format(date).toLowerCase();
  } else {
    return dateFormat.format(date);
  }
}

export function getReviewUrl(owner: string, repo: string, number: number) {
  return `${config.baseUrl()}/pr/${owner}/${repo}/${number}`;
}

export function getCommentHeader(owner: string, repo: string, number: number) {
  return [
    `##### Automated comment from [CodeApprove ➜](${getReviewUrl(
      owner,
      repo,
      number
    )})`,
    "",
  ].join("\n");
}

/**
 * Comments and threads should only be the new ones involved in the history.
 */
export function getHistoryComment(
  review: Review,
  history: ReviewHistory,
  threads: Thread[],
  comments: Comment[]
): string {
  const lines: string[] = [];

  // Add header
  const { owner, repo, number } = review.metadata;
  const header = getCommentHeader(owner, repo, number);
  lines.push(header);
  lines.push("");

  // A new user has approved the PR, and the review is approved
  const reviewApproved = history.approval && isApproved(review.state);

  // The user approved the PR (maybe not new) but the review is stil not approved.
  const unresolvedApproval =
    history.approval &&
    !isApproved(review.state) &&
    review.state.unresolved > 0;

  // A user has newly undone approval
  const newUndoApproval = !history.approval && history.previousApproval;

  if (unresolvedApproval) {
    lines.push(
      `${statusEmoji(ReviewStatus.NEEDS_REVIEW)} Approval Pending (${
        review.state.unresolved
      } unresolved comments)`
    );

    lines.push(
      `_Approval will be granted automatically when all comments are resolved_`
    );
  } else if (reviewApproved) {
    // Add a fun stamp when the review is completely approved
    lines.push(
      `<a href="${getReviewUrl(
        owner,
        repo,
        number
      )}"><img src="https://codeapprove.com/external/github-stamp-allbg.png" width="159" alt="Approved on CodeApprove" /></a>`
    );

    lines.push(`${statusEmoji(ReviewStatus.APPROVED)} Approved`);
  } else if (newUndoApproval) {
    let msg = `${statusEmoji(ReviewStatus.NEEDS_RESOLUTION)} Undo Approval`;
    if (review.state.unresolved > 0) {
      msg += ` (${review.state.unresolved} unresolved comments)`;
    }
    lines.push(msg);
  }

  // Map threadsById
  const threadsById: Record<string, Thread> = {};
  for (const t of threads) {
    threadsById[t.id] = t;
  }

  // If there is a reviewSummary content, add it here
  const summaryComment = comments.find((c) => c.reviewSummary);
  const nonSummaryComments = comments.filter((c) => !c.reviewSummary);

  if (summaryComment) {
    lines.push("");
    lines.push(summaryComment.text);
  }

  // Sort non-summary comments by file name and line
  const sortedComments = nonSummaryComments.sort((a, b) => {
    const threadA = threadsById[a.threadId];
    const threadB = threadsById[b.threadId];

    if (threadA.currentArgs.file !== threadB.currentArgs.file) {
      return threadA.currentArgs.file.localeCompare(threadB.currentArgs.file);
    }

    return threadA.currentArgs.line - threadB.currentArgs.line;
  });

  if (sortedComments.length > 0) {
    lines.push("");
  }

  sortedComments.forEach((c) => {
    const thread = threadsById[c.threadId];
    const args = thread.currentArgs;

    // Add a horiztal line as a divider
    lines.push("<hr />");
    lines.push("");

    if (args.file !== "") {
      lines.push(`In: **${args.file}**:`);
    } else {
      lines.push(`In: **Discussion**`);
    }

    if (args.lineContent !== "") {
      lines.push("");
      lines.push("```");
      lines.push(`> Line ${args.line}`);
      lines.push(`${args.lineContent}`);
      lines.push("```");
    }

    // Add comment text
    lines.push(c.text);
    lines.push("");
  });

  // Ping Assignees
  const otherAssignees = review.state.assignees.filter(
    (a) => a !== history.username
  );
  if (otherAssignees.length > 0) {
    lines.push("");
    lines.push("<hr />");
    lines.push("");

    const assigneesList = otherAssignees.map((r) => `@${r}`).join(", ");
    const assigneesDescription = `👀 ${assigneesList} it's your turn please take a look`;
    lines.push(assigneesDescription);
    lines.push("");
  }

  return lines.join("\n");
}

export function getNewAssigneesComment(
  metadata: ReviewMetadata,
  assignees: string[]
): string {
  const lines: string[] = [];

  const { owner, repo, number } = metadata;
  const header = getCommentHeader(owner, repo, number);
  lines.push(header);

  const assigneesList = assignees.map((r) => `@${r}`).join(", ");
  const assigneesDescription = `👀 ${assigneesList} it's your turn, please take a look`;
  lines.push(assigneesDescription);

  return lines.join("\n");
}

export function getNewCcsComment(
  metadata: ReviewMetadata,
  ccs: string[]
): string {
  const lines: string[] = [];

  const { owner, repo, number } = metadata;
  const header = getCommentHeader(owner, repo, number);
  lines.push(header);

  const ccsList = ccs.map((r) => `@${r}`).join(", ");
  const ccsDescription = `📥 ${ccsList} you've been cc'ed on this review, check it out`;
  lines.push(ccsDescription);

  return lines.join("\n");
}

export function getReviewRequestedComment(
  metadata: ReviewMetadata,
  reviewers: string[]
): string {
  const lines: string[] = [];

  const { owner, repo, number } = metadata;
  const header = getCommentHeader(owner, repo, number);
  lines.push(header);

  const reviewersList = reviewers.map((r) => `@${r}`).join(", ");
  const reviewersDescription = `⏳ ${reviewersList} please review this Pull Request`;
  lines.push(reviewersDescription);

  return lines.join("\n");
}

/**
 * Review types on GitHub
 */
export type ReviewType = "APPROVE" | "REQUEST_CHANGES" | "COMMENT";

/**
 * Reviewer state on CodeApprove
 */
export type ReviewerState =
  | "APPROVED"
  | "APPROVED_PENDING"
  | "REQUESTED_CHANGES"
  | "OTHER";

export function countUnresolvedByReviewer(
  review: Review,
  threads: Thread[]
): Record<string, number> {
  const res: Record<string, number> = {};

  const unresolvedThreads = threads
    .filter((t) => !t.draft)
    .filter((t) => !t.resolved);

  for (const reviewer of review.state.reviewers) {
    const reviewerUnresolved = unresolvedThreads.filter(
      (t) => t.lastUnresolvedBy.username === reviewer
    );
    res[reviewer] = reviewerUnresolved.length;
  }

  return res;
}

export function calculateReviewerStates(
  review: Review,
  threads: Thread[]
): Record<string, ReviewerState> {
  const res: Record<string, ReviewerState> = {};
  const unresolvedByReviewer = countUnresolvedByReviewer(review, threads);

  for (const reviewer of review.state.reviewers) {
    const isApprover = review.state.approvers.includes(reviewer);
    const hasPendingUnresolved = (unresolvedByReviewer[reviewer] || 0) > 0;

    if (isApprover) {
      if (hasPendingUnresolved) {
        res[reviewer] = "APPROVED_PENDING";
      } else {
        res[reviewer] = "APPROVED";
      }
    } else {
      if (hasPendingUnresolved) {
        res[reviewer] = "REQUESTED_CHANGES";
      } else {
        res[reviewer] = "OTHER";
      }
    }
  }

  return res;
}

/**
 *
 * A thread is fully resolved if:
 *  - It is marked 'resolved' (not as draft)
 *  - It has multiple comments
 */
export function isFullyResolved(thread: Thread, comments: Comment[]): boolean {
  if (!thread.resolved) {
    return false;
  }

  const author = thread.username;
  const hasOtherParticipants = comments.some((c) => c.username !== author);

  let lastResolutionIndex = 0;
  for (let i = comments.length - 1; i >= 0; i--) {
    if (comments[i].setThreadResolution === true) {
      lastResolutionIndex = i;
      break;
    }
  }

  return hasOtherParticipants || lastResolutionIndex > 0;
}

export function splitFilter<T>(arr: T[], predicate: (t: T) => boolean): T[][] {
  const a = arr.filter(predicate);
  const b = arr.filter((x) => !predicate(x));

  return [a, b];
}

export function findLastIndex<T>(
  arr: T[],
  predicate: (t: T) => boolean
): number {
  let lastTrue = -1;
  for (let i = 0; i < arr.length; i++) {
    if (predicate(arr[i])) {
      lastTrue = i;
    }
  }

  return lastTrue;
}

export function setAdd<T>(arr: T[], item: T) {
  if (!arr.includes(item)) {
    arr.push(item);
  }
}

export function setRemove<T>(arr: T[], item: T) {
  const ind = arr.indexOf(item);
  if (ind >= 0) {
    arr.splice(ind, 1);
  }
}

export function arraysEqual<T>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  for (const item of a) {
    if (!b.includes(item)) {
      return false;
    }
  }

  return true;
}

/**
 * Returns the change from a to b.
 */
export function arrayDiff<T>(a: T[], b: T[]) {
  const aSet = new Set(a);
  const bSet = new Set(b);

  const added = b.filter((x) => !aSet.has(x));
  const removed = a.filter((x) => !bSet.has(x));

  return {
    added,
    removed,
  };
}

// TODO: Use a deep equal library
export function actionsEqual(a: ReviewAction, b: ReviewAction) {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (const key of aKeys) {
    if ((a as any)[key] !== (b as any)[key]) {
      return false;
    }
  }

  return true;
}

export function getDiffLabel(pointer: ReviewPointer): string {
  // We need to do this transformation to protect against
  // deleted branches.
  //
  // The label='user:branch', ref='branch', sha='abc123'
  return `${pointer.user.login}:${pointer.sha}`;
}

/**
 * Common utility for a definition of "nearly expired"
 */
export function tokenNearlyExpired(expires?: number): boolean {
  if (expires === undefined || expires === null) {
    return true;
  }

  const now = new Date().getTime();
  const until = expires - now;
  const fiveMinutesInMs = 5 * 60 * 1000;

  const nearlyExpired = until < fiveMinutesInMs;
  if (nearlyExpired) {
    console.log(
      `Token expires in ${expires} - ${now} = ${until}ms, refreshing authentication`
    );
  }

  return nearlyExpired;
}

export function getEmptyHistory(
  username: string,
  actions: ReviewAction[],
  identifier: ReviewIdentifier
): ReviewHistory {
  return {
    username,
    approval: null,
    previousApproval: null,
    actions: [...actions],
    timestamp: new Date().getTime(),
    metadata: {
      owner: identifier.owner,
      repo: identifier.repo,
    },
  };
}

export type ReviewHistorySummary = {
  reviewers: {
    added: string[];
    removed: string[];
  };
  assignees: {
    added: string[];
    removed: string[];
  };
  ccs: {
    added: string[];
    removed: string[];
  };
};

export function summarizeHistory(history: ReviewHistory) {
  const res: ReviewHistorySummary = {
    reviewers: {
      added: [],
      removed: [],
    },
    assignees: {
      added: [],
      removed: [],
    },
    ccs: {
      added: [],
      removed: [],
    },
  };

  for (const a of history.actions) {
    switch (a.type) {
      case "add_reviewer":
        res.reviewers.added.push(a.username);
        break;
      case "remove_reviewer":
        res.reviewers.removed.push(a.username);
        break;
      case "add_assignee":
        res.assignees.added.push(a.username);
        break;
      case "remove_assignee":
        res.assignees.removed.push(a.username);
        break;
      case "add_cc":
        res.ccs.added.push(a.username);
        break;
      case "remove_cc":
        res.ccs.removed.push(a.username);
        break;
    }
  }

  return res;
}

export function historyHasApprovalChange(history: ReviewHistory) {
  return (
    history.approval != null && history.approval !== history.previousApproval
  );
}

export function historyHasCommentActions(history: ReviewHistory) {
  return history.actions.some((a) => a.type === "add_comment");
}

export function historyIsReviewCreated(history: ReviewHistory) {
  return history.actions.some((a) => a.type === "create_review");
}

export function getHistorySummaryText(history: ReviewHistory) {
  if (historyHasApprovalChange(history)) {
    return summarizeHistoryComments(history);
  }

  if (historyHasCommentActions(history)) {
    return summarizeHistoryComments(history);
  }

  if (historyIsReviewCreated(history)) {
    return "Opened the Pull Request";
  }

  return summarizeHistoryReviewers(history);
}

export function summarizeHistoryReviewers(history: ReviewHistory): string {
  const reviewerAdds = history.actions.filter(
    (a) => a.type === "add_reviewer"
  ) as AddReviewerAction[];

  const reviewerRemoves = history.actions.filter(
    (a) => a.type === "remove_reviewer"
  ) as RemoveReviewerAction[];

  const assigneeAdds = history.actions.filter(
    (a) => a.type === "add_assignee"
  ) as AddAssigneeAction[];

  const assigneeRemoves = history.actions.filter(
    (a) => a.type === "remove_assignee"
  ) as RemoveAssigneeAction[];

  const ccAdds = history.actions.filter(
    (a) => a.type === "add_cc"
  ) as AddCcAction[];

  const ccRemoves = history.actions.filter(
    (a) => a.type === "remove_cc"
  ) as RemoveCcAction[];

  let summary: string[] = [];

  if (reviewerAdds.length > 0) {
    const names = reviewerAdds.map((a) => a.username);
    summary.push(`added reviewers ${names.join(", ")}`);
  }

  if (reviewerRemoves.length > 0) {
    const names = reviewerRemoves.map((a) => a.username);
    summary.push(`removed reviewers ${names.join(", ")}`);
  }

  if (assigneeAdds.length > 0) {
    const names = assigneeAdds.map((a) => a.username);
    summary.push(`added assignees ${names.join(", ")}`);
  }

  if (assigneeRemoves.length > 0) {
    const names = assigneeRemoves.map((a) => a.username);
    summary.push(`removed assignees ${names.join(",")}`);
  }

  if (ccAdds.length > 0) {
    const names = ccAdds.map((a) => a.username);
    summary.push(`cc'ed ${names.join(", ")}`);
  }

  if (ccRemoves.length > 0) {
    const names = ccRemoves.map((a) => a.username);
    summary.push(`un-cc'ed ${names.join(",")}`);
  }

  const joined = summary.join(", ");
  return joined.substr(0, 1).toLocaleUpperCase() + joined.substring(1);
}

export function summarizeHistoryComments(history: ReviewHistory): string {
  const addCommentActions = history.actions.filter(
    (a) => a.type === "add_comment"
  ) as AddCommentAction[];

  const addThreadActions = history.actions.filter(
    (a) => a.type === "add_thread"
  ) as AddThreadAction[];

  const replyActions = addCommentActions.filter(
    (ac) => !addThreadActions.some((at) => at.threadId === ac.threadId)
  );

  const numNew = addThreadActions.length;
  const numReplies = replyActions.length;

  if (numNew > 0 && numReplies > 0) {
    return `Added ${numNew} new comments and ${numReplies} replies`;
  } else if (numNew > 0) {
    return `Added ${numNew} new comments`;
  } else if (numReplies > 0) {
    return `Replied to ${numReplies} comments`;
  } else {
    return `No comments`;
  }
}

export function userMatches(item: UserSearchItem, query: string) {
  if (item.login.toLowerCase().includes(query.toLowerCase())) {
    return true;
  }

  if (item.name && item.name.toLowerCase().includes(query.toLowerCase())) {
    return true;
  }

  return false;
}

export function canShadowAsAdmin(user: User) {
  return user.email?.endsWith("codeapprove.com") && user.admin;
}

export const getOrDefault = <T>(val: T | undefined, defaultVal: T): T => {
  if (val === undefined || val === null) {
    return defaultVal;
  }

  return val;
};
