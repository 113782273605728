
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import parseDiff from "parse-diff";

import { ChangeEntryAPI, ChunkAPI } from "../api";

import Chunk, { type ChunkLineClickEvent } from "@/components/elements/Chunk.vue";
import ChunkHeaderBar from "@/components/elements/ChunkHeaderBar.vue";
import CommentThread from "@/components/elements/CommentThread.vue";
import AuthModule from "../../store/modules/auth";
import ReviewModule from "../../store/modules/review";
import UIModule from "../../store/modules/ui";
import { SidePair } from "../../model/review";
import {
  AddCommentEvent,
  LINE_CLICK_EVENT
} from "../../plugins/events";
import { nextRender, makeTopVisible } from "../../plugins/dom";
import { getFileLang } from "../../plugins/prism";

import { Github } from "../../../../shared/github";

import {
  Thread,
  Side,
  RepoBranchPair,
  DiffMode,
  THREAD_LINE_WHOLE_FILE
} from "../../../../shared/types";
import {
  RenderedChangePair,
  FileMetadata,
  ChunkData,
  START_CHUNK,
  fileMetadataTitle,
  END_CHUNK,
  getLocationIdPrefix,
  PullRequestChange,
  getLocationId,
  chooseThreadArgs,
  changeMatchesThreadArgs
} from "../../plugins/diff";
import { KeyMap, CHANGE_ENTRY_KEY_MAP } from "../../plugins/hotkeys";
import {
  isBinaryFile,
  isImageFile,
  getExt,
  getMimeType
} from "../../plugins/binary";
import { config } from "../../plugins/config";

export type ChangeEntryLineClickEvent = {
  file: string;
  side: Side;
  line: number;
};

@Component({
  components: {
    ChunkHeaderBar,
    Chunk,
    CommentThread
  }
})
export default class ChangeEntry extends Vue implements ChangeEntryAPI {
  @Prop() public mode!: DiffMode;
  @Prop() public changeKey!: string;
  @Prop({ default: null }) repoBranchPair!: RepoBranchPair | null;
  @Prop({ default: false }) readOnly!: boolean;
  @Prop() index!: number;

  @Prop({ default: null }) prChange!: PullRequestChange;

  public sticky = false;
  public active = false;
  public loading = false;
  public loaded = false;
  public expanded = false;
  public activeLineIndex = -1;
  public showLastChunkHeader = true;
  public draftingWholeFileThread = false;

  /** true when new lines are loading, to prevent double loads */
  public isLoadingLines = false;

  public imageContent: SidePair<string | null> = {
    left: null,
    right: null
  };

  private orderedChunks: ChunkAPI[] = [];

  private authModule = getModule(AuthModule, this.$store);
  private reviewModule = getModule(ReviewModule, this.$store);
  private uiModule = getModule(UIModule, this.$store);

  private github: Github = new Github(
    AuthModule.getDelegate(this.authModule),
    config.github
  );

  private observer: IntersectionObserver | null = null;
  private intersectionMargin = 0;

  async mounted() {
    // When a file is added or deleted we are always seeing the whole picture
    if (this.added || this.deleted) {
      this.showLastChunkHeader = false;
    }

    if (this.isBinary) {
      this.showLastChunkHeader = false;
    }

    const topTarget = this.getChildById("#top-intersector");
    const bottomTarget = this.getChildById("#bottom-intersector");

    const prHeader = document.querySelector("#prheader");
    this.intersectionMargin = prHeader
      ? prHeader.getBoundingClientRect().height
      : 0;

    const changeHeader = this.getChildById("#changeheader");
    changeHeader.style.top = `${this.intersectionMargin}px`;

    this.observer = new IntersectionObserver(
      (entries, observer) => {
        const topIsClipped =
          topTarget.getBoundingClientRect().top < this.intersectionMargin;
        const bottomIsClipped =
          bottomTarget.getBoundingClientRect().top <
          this.intersectionMargin + topTarget.offsetHeight;

        if (topIsClipped && !bottomIsClipped) {
          if (this.expanded) {
            this.setHeaderSticky(true);
          }
        } else {
          this.setHeaderSticky(false);
        }
      },
      {
        root: null,
        rootMargin: `${this.intersectionMargin}px`,
        threshold: 1.0
      }
    );

    if (topTarget && bottomTarget) {
      this.observer.observe(topTarget);
      this.observer.observe(bottomTarget);
    }
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  get change(): PullRequestChange {
    // In readonly mode we provide the change
    if (this.prChange) {
      return this.prChange;
    }
    return this.reviewModule.viewState.prChanges[this.changeKey]!;
  }

  get meta(): FileMetadata {
    return this.change.metadata;
  }

  get chunks(): ChunkData[] {
    return this.change.data;
  }

  public getChildById(id: string) {
    return this.$el.querySelector(id) as HTMLElement;
  }

  public setHeaderSticky(sticky: boolean) {
    const ghostHeader = this.getChildById("#ghostheader");
    if (sticky) {
      ghostHeader.classList.remove("hidden");
    } else {
      ghostHeader.classList.add("hidden");
    }
    this.sticky = sticky;
  }

  public isReviewed() {
    return this.reviewed;
  }

  public getIndex() {
    return this.index;
  }

  public toggleReviewed() {
    this.setReviewed(!this.reviewed);
  }

  public toggleWholeFileThread() {
    if (!this.wholeFileThreadId) {
      this.draftingWholeFileThread = !this.draftingWholeFileThread;
    }

    if (
      (this.draftingWholeFileThread || this.wholeFileThreadId) &&
      !this.expanded
    ) {
      this.expand();
    }
  }

  public onWholeFileCommentEvent(e: Partial<AddCommentEvent>) {
    if (e.type === "new_thread_add_comment") {
      e.lineContent = "";
    }

    this.onCommentEvent(e);
  }

  public async setReviewed(reviewed: boolean) {
    if (reviewed) {
      this.collapse();
    }

    await this.reviewModule.markFileReviewed({
      filename: this.fileTitle,
      login: this.authModule.assertUser.username,
      reviewed
    });
  }

  get reviewed(): boolean {
    return !!this.reviewModule.reviewUserData.filesReviewed[this.fileTitle];
  }

  @Emit(LINE_CLICK_EVENT)
  public onLineClickEvent(e: ChunkLineClickEvent): ChangeEntryLineClickEvent {
    const file = e.side === "right" ? this.meta.to : this.meta.from;
    return {
      ...e,
      file
    }
  }

  public onCommentEvent(e: Partial<AddCommentEvent>) {
    console.log(`ChangeEntry#onCommentEvent: ${JSON.stringify(e)}`);

    // For a comment without a thread ID, add what we know
    if (e.type === "new_thread_add_comment") {
      if (e.sha) {
        const base = this.reviewModule.viewState.base;
        e.file = e.sha === base ? this.meta.from : this.meta.to;
      }
    }

    this.$emit("comment", e);
  }

  get tooBig(): boolean {
    return this.meta.additions + this.meta.deletions >= 1500;
  }

  get isMoved(): boolean {
    return this.meta.from !== this.meta.to;
  }

  get canLoadWholeFile(): boolean {
    return this.isMoved && !this.isBinary && this.chunks.length === 0;
  }

  get isBinary(): boolean {
    return isBinaryFile(this.meta.from) || isBinaryFile(this.meta.to);
  }

  get isEmptyChange(): boolean {
    return this.chunks.length === 1 && this.chunks[0].pairs.length === 0;
  }

  get isImage(): boolean {
    return isImageFile(this.meta.from) || isImageFile(this.meta.to);
  }

  get canExpand(): boolean {
    const isNonImageBinary = this.isBinary && !this.isImage;
    return !(this.tooBig || isNonImageBinary || this.isEmptyChange);
  }

  get cantExpandMessage(): string {
    if (this.tooBig) {
      return "This diff is too large to render, please view it locally.";
    }

    if (this.isBinary) {
      return "Binary file.";
    }

    return "";
  }

  get hotKeyMap(): KeyMap {
    return CHANGE_ENTRY_KEY_MAP(this);
  }

  get numLinesChanged(): string {
    return `${this.meta.additions + this.meta.deletions}`.padStart(3, " ");
  }

  get totalLength(): number {
    let totalLength = 0;
    this.chunks.forEach(c => (totalLength += c.pairs.length));
    return totalLength;
  }

  public activate() {
    this.active = true;
  }

  public deactivate() {
    this.active = false;
    this.setActiveDiffLine(-1);
  }

  public toggle() {
    if (!this.expanded) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  public collapse() {
    this.loading = false;
    if (this.expanded) {
      makeTopVisible(this.$el, this.intersectionMargin);
    }
    this.expanded = false;
    this.setHeaderSticky(false);
    this.setActiveDiffLine(-1);
  }

  public isExpanded() {
    return this.expanded;
  }

  public expand() {
    if (this.loaded) {
      this.expanded = true;
      return;
    }

    // Change cannot be expanded (too big, binary, etc), so just infinite-load
    if (!this.canExpand) {
      this.loading = true;
      this.expanded = true;
      return;
    }

    // TODO(polish): Add some benchmarking here!
    const isLarge = this.totalLength >= 100;

    if (!(isLarge || this.isImage)) {
      this.expanded = true;
      this.loaded = true;
      return;
    }

    this.loading = true;

    // Loading:
    // 1) Large change: just wait a tick
    // 2) Image: actually load the content
    let loadedPromise = new Promise(res => nextRender(res));
    if (this.isImage) {
      loadedPromise = this.loadImageContent();
    }

    loadedPromise.then(() => {
      this.expanded = true;
      this.loaded = true;
      this.loading = false;
    });
  }

  @Watch("expanded")
  public onExpandChanged() {
    this.$emit("expand");
  }

  public async loadImageContent() {
    const branchPair =
      this.repoBranchPair !== null
        ? this.repoBranchPair
        : this.reviewModule.repoBranchPair;

    const { owner, repo, base, head } = branchPair;

    if (!this.added) {
      const left = await this.github.getBinaryFileContent(
        owner,
        repo,
        this.meta.from,
        base
      );

      const ext = getExt(this.meta.from);
      const mime = getMimeType(ext);
      this.imageContent.left = `data:${mime};${left.encoding}, ${left.content}`;
    }

    if (!this.deleted) {
      const right = await this.github.getBinaryFileContent(
        owner,
        repo,
        this.meta.to,
        head
      );

      const ext = getExt(this.meta.to);
      const mime = getMimeType(ext);

      // For files over 1MB, the content will not be returned directly. However the
      // download URL will still be present. See:
      // https://docs.github.com/en/rest/repos/contents?apiVersion=2022-11-28#size-limits
      if (right.content) {
        this.imageContent.right = `data:${mime};${right.encoding}, ${right.content}`;
      } else if (right.url) {
        this.imageContent.right = right.url;
      }
    }
  }

  public async loadFileContent() {
    this.uiModule.beginLoading();

    const { owner, repo } = this.reviewModule.review.metadata;
    const { head } = this.reviewModule.viewState;
    const fileLines = await this.github.getContentLines(
      owner,
      repo,
      this.meta.to,
      head,
      0
    );

    this.reviewModule.addLinesToChunk({
      changeKey: this.changeKey,
      chunkIndex: 0,
      addTo: "end",
      leftLines: fileLines,
      rightLines: fileLines,
      readOnly: this.readOnly,
      username: this.authModule.assertUser.username
    });

    this.uiModule.endLoading();
  }

  public hasHiddenThreads() {
    if (!this.loaded) {
      return false;
    }

    return this.getHiddenThreads().length > 0;
  }

  /**
   * Get the distance of a line in a file from a chunk.
   *   - If the line is within the chunk, return 0.
   *   - If the line is before the chunk, return a negative number of how far.
   *   - If the line is after the chunk, return a positive number of how far.
   */
  public lineDistance(chunk: parseDiff.Chunk, side: Side, line: number) {
    if (side === "left") {
      if (line < chunk.oldStart) {
        return line - chunk.oldStart;
      }

      const oldEnd = chunk.oldStart + chunk.oldLines - 1;
      if (line > oldEnd) {
        return line - oldEnd;
      }
    }

    if (side === "right") {
      if (line < chunk.newStart) {
        return line - chunk.newStart;
      }

      const newEnd = chunk.newStart + chunk.newLines - 1;
      if (line > newEnd) {
        return line - newEnd;
      }
    }

    return 0;
  }

  public async showHiddenThreads() {
    if (this.canLoadWholeFile) {
      return this.loadFileContent();
    }

    const threads = this.getHiddenThreads();

    // Add END_CHUNK at the end so we can catch comments that are off the bottom
    const chunks = this.chunks.map(c => c.chunk);
    chunks.push(END_CHUNK);

    const base = this.reviewModule.viewState.base;
    const head = this.reviewModule.viewState.head;

    for (const t of threads) {
      for (let i = 0; i < chunks.length; i++) {
        const curr = chunks[i];
        const prev = this.getPrevChunk(i);

        // Get the args we're using to show it
        const { side, line } = chooseThreadArgs(t, base, head);

        const distanceToPrev = this.lineDistance(prev, side, line);
        const distanceToCurrent = this.lineDistance(curr, side, line);

        // Determine if the thread is between chunk i-1 and i (prev and current)
        const isAfterPrev = distanceToPrev > 0;
        const isBeforeCurrent = distanceToCurrent < 0;
        const isBetweenPrevAndCurrent = isAfterPrev && isBeforeCurrent;

        if (isBetweenPrevAndCurrent) {
          // We can either load one of two ways depending on efficiency
          //  a) Above the current chunk
          //  b) Below the previous one
          //
          // We can't load below the previous one though if we're already at
          // the top.
          const isCurrentCloser =
            i === 0 || Math.abs(distanceToCurrent) <= Math.abs(distanceToPrev);

          if (isCurrentCloser) {
            console.log(`Loading above chunk ${i} to show thread ${t.id}`);
            await this.loadLinesAround(
              "above",
              i,
              Math.abs(distanceToCurrent) + 10
            );
            break;
          } else {
            console.log(`Loading below chunk ${i - 1} to show thread ${t.id}`);
            await this.loadLinesAround(
              "below",
              i - 1,
              Math.abs(distanceToPrev) + 10
            );
            break;
          }
        }
      }
    }
  }

  /**
   * Get all of the unresolved threads that are attached to lines which we can't see.
   */
  public getHiddenThreads() {
    const visibleLocationIds: Record<string, boolean> = {};

    const base = this.reviewModule.viewState.base;
    const head = this.reviewModule.viewState.head;

    // Collect all visible lines into a map
    for (let i = 0; i < this.chunks.length; i++) {
      const chunk = this.chunks[i];
      const prefix = this.getLocationIdPrefixForChunk(i);

      for (const pair of chunk.pairs) {
        const leftId = getLocationId(prefix, pair.left.number, "left");
        const rightId = getLocationId(prefix, pair.right.number, "right");

        visibleLocationIds[leftId] = true;
        visibleLocationIds[rightId] = true;
      }
    }

    // Make whole file comments visible
    visibleLocationIds[this.getWholeFileLocationId()] = true;

    // Look for threads that match this file and the SHA
    const threadsInFile = this.reviewModule.visibleThreads.filter(t => {
      const args = chooseThreadArgs(t, base, head);
      return (
        changeMatchesThreadArgs(this.change, args, "left") ||
        changeMatchesThreadArgs(this.change, args, "right")
      );
    });

    // Hidden if unresolved and not visible and there's an exact sha match
    return threadsInFile.filter(t => {
      const threadLocationId = this.reviewModule.viewState.threadIdToLocationId[
        t.id
      ];
      const isVisible =
        threadLocationId && visibleLocationIds[threadLocationId];

      if (isVisible) {
        return false;
      }

      const args = chooseThreadArgs(t, base, head);
      const exactShaMatch = args.sha === base || args.sha === head;

      if (!t.resolved && exactShaMatch) {
        return true;
      }
    });
  }

  public getWholeFileLocationId() {
    const wholeFilePrefix = this.getLocationIdPrefixForChunk(-1);
    const wholeFileRightId = getLocationId(
      wholeFilePrefix,
      THREAD_LINE_WHOLE_FILE,
      "right"
    );
    return wholeFileRightId;
  }

  get wholeFileThreadId() {
    return (
      this.reviewModule.viewState.locationIdToThreadId[
        this.getWholeFileLocationId()
      ] || null
    );
  }

  /**
   * Get the index of the first line within the chunk.
   */
  public getChunkLineIndex(chunkIndex: number) {
    let sum = 0;
    for (let i = 0; i < chunkIndex; i++) {
      sum += this.chunks[i].pairs.length;
    }

    return sum;
  }

  /**
   * Get the index of a specific line within the chunk.
   */
  public getLineIndex(chunkIndex: number, lineIndex: number) {
    return this.getChunkLineIndex(chunkIndex) + lineIndex;
  }

  /**
   * Get the location index to pass down to the chunks.
   */
  public getLocationIdPrefixForChunk(chunkIndex: number) {
    return getLocationIdPrefix(this.change.key, chunkIndex);
  }

  /**
   * Determine how many digits should be used to display the line number.
   */
  get calculateLineNumberDigits() {
    // Get the max line number we are showing
    const lastChunk = this.chunks[this.chunks.length - 1];
    const lastPair = lastChunk.pairs[lastChunk.pairs.length - 1];

    let max = 1;
    max = Math.max(max, lastPair.left.number);
    max = Math.max(max, lastPair.right.number);

    // Determine how many digits it has
    // log10(1) = 0, log10(10) = 1, log10(100) = 2
    return Math.floor(Math.log10(max)) + 1;
  }

  public sortChunks() {
    const chunkEls = this.$refs.chunks as ChunkAPI[];
    if (chunkEls === undefined || chunkEls.length === 0) {
      console.log(`sortChunks: no chunks to sort`);
      return;
    }

    this.orderedChunks = chunkEls.sort((a, b) => {
      return a.getIndex() - b.getIndex();
    });
  }

  public setActiveDiffLine(index: number) {
    if (index < 0 || this.activeLineIndex < 0) {
      this.sortChunks();
    }

    const chunk = this.getCurrentChunk();
    if (chunk) {
      chunk.setActiveLine(-1);
    }

    this.activeLineIndex = index;

    const nextChunk = this.getCurrentChunk();
    if (nextChunk) {
      nextChunk.setActiveLine(index - nextChunk.getIndex());
    }
  }

  public setActiveFileLine(line: number, side: Side, options?: { disableScroll: boolean }) {
    this.sortChunks();

    const lineIndex = this.findIndexOfLine(line, side);
    if (lineIndex >= 0) {
      console.log(`${side} file line ${line} is index ${lineIndex}`);
      this.setActiveDiffLine(lineIndex);

      if (!options?.disableScroll) {
        this.scrollToCurrentLine();
      }
      return;
    }

    console.log(`Could not find ${side} file line ${line}, hidden?`);
  }

  public nextLine(e: Event) {
    const max = this.totalLength - 1;
    if (this.activeLineIndex === max) {
      return;
    }

    this.setActiveDiffLine(Math.min(this.activeLineIndex + 1, max));
    this.scrollToCurrentLine();
    e.preventDefault();
  }

  public prevLine(e: Event) {
    if (this.activeLineIndex === 0) {
      return;
    }

    // TODO(polish): When scrolling up we don't properly account for the header
    this.setActiveDiffLine(Math.max(this.activeLineIndex - 1, 0));
    this.scrollToCurrentLine();
    e.preventDefault();
  }

  private scrollToCurrentLine() {
    const currentChunk = this.getCurrentChunk();
    if (currentChunk) {
      currentChunk.scrollToActiveLine();
    }
  }

  public addLineComment(e: Event) {
    e.preventDefault();
    // It turns out that binding/unbinding the keymap is slow
    // so instead we check the active status here.
    if (!this.active) {
      return;
    }

    const chunk = this.getCurrentChunk();
    if (chunk) {
      chunk.addComment(this.activeLineIndex - chunk.getIndex());
    }
  }

  public findChangeByIndex(index: number): RenderedChangePair | undefined {
    if (index < 0) {
      return;
    }

    let remaining = index;
    for (const chunk of this.chunks) {
      if (chunk.pairs.length <= remaining) {
        return chunk.pairs[remaining];
      }

      remaining -= chunk.pairs.length;
    }
  }

  public findIndexOfLine(lineNumber: number, side: Side): number {
    let base = 0;
    for (const chunk of this.chunks) {
      const ind = chunk.pairs.findIndex(p => {
        const sideNumber = side === "right" ? p.right.number : p.left.number;
        return sideNumber === lineNumber;
      });

      if (ind >= 0) {
        return base + ind;
      }

      base += chunk.pairs.length;
    }

    return -1;
  }

  public getCurrentChunk(): ChunkAPI | undefined {
    if (!this.orderedChunks || this.activeLineIndex < 0) {
      return;
    }

    let remaining = this.activeLineIndex;
    for (let i = 0; i < this.chunks.length; i++) {
      const c = this.chunks[i];
      remaining -= c.pairs.length;
      if (remaining < 0) {
        return this.orderedChunks[i];
      }
    }
  }

  public getPrevChunk(index: number): parseDiff.Chunk {
    return index > 0 ? this.chunks[index - 1].chunk : START_CHUNK;
  }

  public getNextChunk(index: number): parseDiff.Chunk {
    return index < this.chunks.length - 1
      ? this.chunks[index + 1].chunk
      : END_CHUNK;
  }

  public showChunkHeader(index: number) {
    const chunk = this.chunks[index].chunk;
    const prevChunk = this.getPrevChunk(index);

    const prevLeftEnd = prevChunk.oldStart + prevChunk.oldLines - 1;

    // Check if the chunks are directly adjacent
    const adjacentToPrev = chunk.oldStart === prevLeftEnd + 1;

    // Check if the chunk starts the file
    const bothAtStart = chunk.oldStart <= 1 && chunk.newStart <= 1;

    return !(adjacentToPrev || bothAtStart);
  }

  public async loadLinesAround(
    direction: "above" | "below",
    index: number,
    count: number = 20
  ) {
    if (index < 0) {
      console.warn(`Attempted to load invalid chunk: ${index}`);
      return;
    }

    this.isLoadingLines = true;

    try {
      switch (direction) {
        case "above":
          await this.loadLinesAbove(index, count);
          break;
        case "below":
          await this.loadLinesBelow(index, count);
          break;
      }
    } catch (e) {
      // Ignore
    }

    this.isLoadingLines = false;
  }

  public async loadLinesBelow(index: number, count: number = 20) {
    this.uiModule.beginLoading();

    const chunk = this.chunks[index].chunk;
    const nextChunk = this.getNextChunk(index);

    const { owner, repo } = this.reviewModule.review.metadata;
    const { base, head } = this.reviewModule.viewState;

    const currentLeftEnd = chunk.oldStart + chunk.oldLines - 1;
    const nextLeftStart = nextChunk.oldStart;

    const leftStart = currentLeftEnd + 1;
    const leftEnd = Math.min(leftStart + count - 1, nextLeftStart - 1);

    const currentRightEnd = chunk.newStart + chunk.newLines - 1;
    const nextRightStart = nextChunk.newStart;

    const rightStart = currentRightEnd + 1;
    const rightEnd = Math.min(rightStart + count - 1, nextRightStart - 1);

    const leftLines = await this.github.getContentLines(
      owner,
      repo,
      this.meta.from,
      base,
      leftStart,
      leftEnd
    );

    const rightLines = await this.github.getContentLines(
      owner,
      repo,
      this.meta.to,
      head,
      rightStart,
      rightEnd
    );

    // We tried to load past the end and there was nothing there, hide the bar
    const isLastChunk = index === this.chunks.length - 1;
    const reachedEnd = rightLines.length < count && leftLines.length < count;
    if (isLastChunk && reachedEnd) {
      this.showLastChunkHeader = false;
    }

    const activeLineBefore = this.findChangeByIndex(this.activeLineIndex);

    this.reviewModule.addLinesToChunk({
      changeKey: this.changeKey,
      chunkIndex: index,
      addTo: "end",
      leftLines,
      rightLines,
      readOnly: this.readOnly,
      username: this.authModule.assertUser.username
    });

    if (activeLineBefore) {
      nextRender(() => {
        this.setActiveFileLine(activeLineBefore.right.number, "right");
      });
    }

    this.uiModule.endLoading();
  }

  public async loadLinesAbove(index: number, count: number = 20) {
    this.uiModule.beginLoading();

    const chunk = this.chunks[index].chunk;
    const prevChunk = this.getPrevChunk(index);

    const { owner, repo } = this.reviewModule.review.metadata;
    const { base, head } = this.reviewModule.viewState;

    // Determine where the previous left-side chunk ends
    const prevLeftEnd = prevChunk.oldStart + prevChunk.oldLines - 1;

    // Load either {count} lines or everything between the two chunks
    const leftStart = Math.max(chunk.oldStart - (count - 1), prevLeftEnd + 1);
    const leftEnd = Math.max(chunk.oldStart - 1, 1);

    // Determine where the previous right-side chunk ends
    const prevRightEnd = prevChunk.newStart + prevChunk.newLines - 1;

    // Load either {count} lines or everything between the two chunks
    const rightStart = Math.max(chunk.newStart - (count - 1), prevRightEnd + 1);
    const rightEnd = Math.max(chunk.newStart - 1, 1);

    const leftLines = await this.github.getContentLines(
      owner,
      repo,
      this.meta.from,
      base,
      leftStart,
      leftEnd
    );

    const rightLines = await this.github.getContentLines(
      owner,
      repo,
      this.meta.to,
      head,
      rightStart,
      rightEnd
    );

    const activeLineBefore = this.findChangeByIndex(this.activeLineIndex);

    this.reviewModule.addLinesToChunk({
      changeKey: this.changeKey,
      chunkIndex: index,
      addTo: "start",
      leftLines,
      rightLines,
      readOnly: this.readOnly,
      username: this.authModule.assertUser.username
    });

    if (activeLineBefore) {
      nextRender(() => {
        this.setActiveFileLine(activeLineBefore.right.number, "right");
      });
    }

    this.uiModule.endLoading();
  }

  get langPair() {
    return {
      left: getFileLang(this.meta.from),
      right: getFileLang(this.meta.to)
    };
  }

  get allThreads() {
    const left: Thread[] = this.reviewModule.threadsByFile(this.meta.from);
    const right: Thread[] = this.reviewModule.threadsByFile(this.meta.to);

    const all = [...left];
    for (const thread of right) {
      if (!all.find(x => thread.id === x.id)) {
        all.push(thread);
      }
    }

    return all;
  }

  get countUnresolvedThreads() {
    return this.allThreads.filter(t => !t.draft && !t.resolved).length;
  }

  get additionPct() {
    if (this.isBinary) {
      if (this.meta.from === "/dev/null") {
        return 100;
      } else if (this.meta.to === "/dev/null") {
        return 0;
      } else {
        return 50;
      }
    }

    const exact =
      this.meta.additions / (this.meta.additions + this.meta.deletions);
    if (exact === 0) {
      return 0;
    } else if (exact === 1) {
      return 100;
    } else {
      const asPct = Math.round(exact * 100);
      return Math.max(10, Math.min(asPct, 90));
    }
  }

  get icon() {
    if (this.expanded) {
      return "caret-down";
    } else {
      return "caret-right";
    }
  }

  get added() {
    return this.meta.from === "/dev/null";
  }

  get deleted() {
    return this.meta.to === "/dev/null";
  }

  get fileTitle() {
    return fileMetadataTitle(this.meta);
  }
}
