
import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import UIModule from "../../store/modules/ui";

@Component
export default class ProgressBar extends Vue {
  @Prop() forceLoading: boolean | undefined;
  @Prop({ default: "large" }) size!: string;

  uiModule = getModule(UIModule, this.$store);

  get loading() {
    if (this.forceLoading !== undefined) {
      return this.forceLoading;
    }

    return this.uiModule.loading;
  }
}
